import {useTheme, Typography, Box, Divider, Grid, Tooltip} from '@mui/material';
import {DeviceCard} from '../../../components';
import {GeneralSectionProps} from './types';
import {StyledContainer} from './styles';
import {GraphsWrapper} from '../../../sections/participant/general/GraphsWrapper';
import {mmolPerLToMgPerdL} from '../../../helpers';
import {useAppSelector} from '../../../store';
import {unitSelector} from '../../../store/reducers/unitSlice';
import {pollingInterval} from '../../../constants/api';
import {useEffect, useRef, useMemo, useState} from 'react';
import {faUserXmark} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {IconProp} from '@fortawesome/fontawesome-svg-core';
import {updateMeal, calculateLastHoursInsulinTotal, getInitialMeal} from './utils';
import {ENDPOINT_TIME_OPTIONS} from '../../../constants';
import {useRefetchData} from '../../../hooks/useRefetchData';
import {getLocalTimestamp, getIsTimeAgo, getTimeAgo} from '../../../../src/utils';

export const GeneralSection = ({generalRef, dynamicData, staticData}: GeneralSectionProps) => {
  const theme = useTheme();
  const {unit} = useAppSelector(unitSelector);
  const intervalRef = useRef<NodeJS.Timeout | null>(null);
  const {id, basalProfiles, totalDailyInsulin} = staticData;
  const {devices, lastMeal, lastMealBolus, mealEvents} = dynamicData;
  const profile = basalProfiles[0] ? basalProfiles[0]?.profile.map(value => value / 12) : []; // get array containing basal profile in U
  const [param, setParam] = useState<string>(`${id}?timeframe=${ENDPOINT_TIME_OPTIONS.THREE_HOURS}`);

  const [meal, setMeal] = useState<{
    timestampCarbs: string | undefined;
    timestampBolus: string | undefined;
    amount: number | undefined;
    value: number | undefined;
  }>({
    timestampCarbs: lastMeal?.eventLocalDateTime,
    timestampBolus: lastMealBolus?.eventLocalDateTime,
    amount: lastMeal?.isHypoTreatment ? getInitialMeal(mealEvents) : lastMeal?.carbohydratesEstimate,
    value:
      lastMealBolus?.injectedValue !== null &&
      (lastMealBolus?.deliveryStatus == 'DELIVERED' || lastMealBolus?.deliveryStatus == 'PARTIALLY_DELIVERED')
        ? lastMealBolus?.injectedValue
        : undefined,
  });

  const {latestCgmData, latestDynamicData, refetchCgmData, refetchDynamicData} = useRefetchData(param, pollingInterval);

  const handleSelectedDateRangeChange = (selectedDateRange: string) => {
    const newParam = `${id}?timeframe=${selectedDateRange}`;
    setParam(newParam);
  };

  useEffect(() => {
    const handleInterval = () => {
      refetchCgmData().then(response => {
        console.log('Fetched cgm data:', response);
      });
      refetchDynamicData().then(response => {
        console.log('Fetched dynamic data:', response);
      });
    };

    intervalRef.current = setInterval(handleInterval, 60000); // update every minute

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [refetchCgmData, refetchDynamicData]);

  // update latest cgm value
  const latestCgm =
    latestCgmData && latestCgmData.find((measurement: {participantId: number}) => measurement.participantId === id);

  const latestCgmValue =
    latestCgm?.measurement != null || (latestCgm?.controllerFilledValues?.length ?? 0) > 0
      ? mmolPerLToMgPerdL(
          latestCgm?.measurement != null
            ? latestCgm.measurement
            : latestCgm?.controllerFilledValues?.[latestCgm.controllerFilledValues.length - 1] ?? 0,
          unit,
        )
      : 0;
  const isInferred = latestCgm?.measurement == null && (latestCgm?.controllerFilledValues?.length ?? 0) > 0;
  const latestLoopMode = latestCgm?.loopMode ?? '--'; // update latest operation mode

  // update insuline sum over last two hours
  const {total, earliestTimepoint} = useMemo(() => {
    const data = latestDynamicData ?? dynamicData;
    return calculateLastHoursInsulinTotal(data, profile);
  }, [latestDynamicData, profile]);
  const isTimeAgo = latestCgm?.eventLocalDateTime && getIsTimeAgo(latestCgm.eventLocalDateTime, 6, true);
  const latestCgmTimestamp = latestCgm?.eventLocalDateTime && getLocalTimestamp(latestCgm.eventLocalDateTime);
  const showInsulinTime = earliestTimepoint && getIsTimeAgo(earliestTimepoint.toString(), 120, false);
  const insulinTimeAgo = latestCgm?.eventLocalDateTime ? getTimeAgo(latestCgm.eventLocalDateTime) : null;

  // update last meal
  useEffect(() => {
    const data = latestDynamicData ?? dynamicData;
    setMeal(prevMeal => updateMeal(data, prevMeal));
  }, [latestDynamicData]);

  return (
    <StyledContainer ref={generalRef}>
      <Box sx={{boxShadow: 1, borderRadius: 2, background: theme.palette.basic[0]}}>
        {staticData && (
          <Box sx={{display: 'flex', flexDirection: 'column', mb: '7px', padding: 2}}>
            <Typography sx={{color: '#9099AA', fontSize: '13px'}}>#{staticData.id}</Typography>
            <Box sx={{display: 'flex', flexDirection: 'row'}}>
              <Typography sx={{color: '#2B2C2E', fontSize: '20px', fontWeight: '600', marginRight: '16px'}}>
                {staticData.name}
                <Tooltip title="Test Participant" arrow>
                  <FontAwesomeIcon
                    icon={faUserXmark as IconProp}
                    size="lg"
                    color="red"
                    style={{marginLeft: '8px', cursor: 'pointer'}}
                  />
                </Tooltip>
              </Typography>
            </Box>
          </Box>
        )}
        <Divider />
        <Grid container xs={12} lg={12} xl={9} item spacing={2} mt={0.5} mb={1} padding={2}>
          <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
            <DeviceCard
              cardHeading="CGM and operation mode"
              showReservoirDetails={false}
              showBatteryPercentage={false}
              lastUpdatedTimeTitle="Last update:"
              lastUpdatedTime={latestCgmTimestamp}
              isTimeAgo={isTimeAgo || undefined}
              showReadingValueAndUnit={true}
              readingValue={latestCgmValue}
              readingUnit={unit}
              readingTrend={latestCgm?.trend}
              tooltipText="Glycemia read by the CGM sensor, current glucose trend (rate of change), and operation mode"
              isDeviceCard={true}
              readingMode={latestLoopMode}
              isInferred={isInferred}
              showLoopMode={true}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
            <DeviceCard
              cardHeading="Insulin pump"
              showReservoirDetails={true}
              reservoirPercentage={devices[1]?.pumpInsulinPercentage || NaN}
              showBatteryPercentage={true}
              batteryPercentage={devices[1]?.batteryPercentage || NaN}
              lastUpdatedTimeTitle="Insulin infused in the last 2h:"
              lastUpdatedTime={String(insulinTimeAgo)}
              showInsulinTime={showInsulinTime}
              showLastHoursInsulin={true}
              readingValue={total}
              isDeviceCard={true}
              tooltipText="Sum of basal, meal boluses, and automatic/ manual correction boluses in the past two hours"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
            <DeviceCard
              cardHeading="Last communicated meal"
              showReservoirDetails={false}
              lastUpdatedTimeTitle="Last meal bolus "
              readingValue={meal.value}
              secondReadingValue={meal.amount}
              lastUpdatedTime={meal?.timestampBolus ? getLocalTimestamp(meal.timestampBolus) : undefined}
              readingMode={meal?.timestampCarbs ? getLocalTimestamp(meal.timestampCarbs) : undefined}
              showLastCarboMeal={true}
              tooltipText="Carbohydrate content of the last communicated meal to the app by the user and injected meal bolus"
            />
          </Grid>
        </Grid>
        <GraphsWrapper
          basalProfiles={basalProfiles}
          dynamicData={latestDynamicData || dynamicData}
          onSelectedDateRangeChange={handleSelectedDateRangeChange}
          TDI={totalDailyInsulin}
        />
      </Box>
    </StyledContainer>
  );
};
