import {LinePath, Circle} from '@visx/shape';
import {scaleLinear} from '@visx/scale';
import {Grid, useTheme} from '@mui/material';
import {AxisBottom, AxisLeft} from '@visx/axis';
import {Group} from '@visx/group';
import {useEffect, useRef, useState, useCallback, useMemo} from 'react';
import {InsulinSensitivity} from '../../../api';
import styled from '@emotion/styled';
import {curveStepAfter} from '@visx/curve';
import GridColumns from '@visx/grid/lib/grids/GridColumns';
import GridRows from '@visx/grid/lib/grids/GridRows';
import {useAppSelector} from '../../../store';
import {unitSelector} from '../../../store/reducers/unitSlice';
import {getEventPosition} from '../../../../src/components/charts/utils';

const InsulinSensitivityChart = (carbohydrateRatios: InsulinSensitivity[]) => {
  const [width, setWidth] = useState(0);
  const height = 300;
  const margin = {top: 20, right: 20, bottom: 30, left: 40};
  const svgRef = useRef<SVGSVGElement>(null);
  const [tooltipData, setTooltipData] = useState<{hour: number; value: number} | null>(null);
  const [tooltipPosition, setTooltipPosition] = useState<{x: number; y: number} | null>(null);
  const [crosshairTime, setCrosshairTime] = useState<number | null>(null);
  const theme = useTheme();
  const {unit} = useAppSelector(unitSelector);
  const convertToMmol = useCallback((value: number) => (unit === 'mmol/L' ? value / 18.018 : value), [unit]);

  useEffect(() => {
    if (svgRef.current) {
      setWidth(svgRef.current.clientWidth);
    }

    const handleResize = () => {
      if (svgRef.current) {
        setWidth(svgRef.current.clientWidth);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const insulinSensitivityI = carbohydrateRatios[0]?.ratios.map(Number);
  const insulinSensitivityII = carbohydrateRatios[1] && carbohydrateRatios[1]?.ratios.map(Number);

  const validInsulinSensitivityI = Array.isArray(insulinSensitivityI) ? insulinSensitivityI : [0];
  const validInsulinSensitivityII = Array.isArray(insulinSensitivityII) ? insulinSensitivityII : [0];
  const extendedValidInsulinSensitivityI = [
    ...validInsulinSensitivityI,
    validInsulinSensitivityI[validInsulinSensitivityI.length - 1],
  ];
  const yScale = scaleLinear({
    domain: [
      convertToMmol(Math.min(...validInsulinSensitivityI, ...validInsulinSensitivityII)),
      convertToMmol(Math.max(...validInsulinSensitivityI, ...validInsulinSensitivityII)),
    ],
    range: [height - margin.bottom, margin.top],
  });

  const xScale = scaleLinear({
    domain: [0, 24],
    range: [margin.left, width - margin.right],
  });

  // y axis tick formatting
  const [yMin, yMax] = yScale.domain();
  const yTicks = yScale.ticks();
  const yTickDist = yTicks[yTicks.length - 1] - yTicks[yTicks.length - 2];
  const yTickPaddingTop = yTicks[yTicks.length - 1] + yTickDist - yMax;
  const yTickPaddingBottom = yMin - yTicks[0] + yTickDist;
  const yTickBottom = parseFloat((yMin - yTickPaddingBottom).toFixed(2));
  const yTickTop = parseFloat((yMax + yTickPaddingTop).toFixed(2));
  yScale.domain([yTickBottom, yTickTop]);

  const numTicks = width <= 780 ? xScale.domain()[1] / 2 : xScale.domain()[1];
  const gridColTicks = xScale.ticks(numTicks).slice(1);
  const gridRowTicks = yScale.ticks().slice(1);

  // mouse hovering window
  const handlePointerMove = useCallback(
    (event: React.PointerEvent<SVGElement>) => {
      const svgRect = svgRef.current?.getBoundingClientRect();
      if (!svgRect) return;
      const {x: mouseXPosition, y: mouseYPosition} = getEventPosition(event, svgRect);

      const closestTimePoint = xScale.invert(mouseXPosition);
      let closestIndex = Math.floor(closestTimePoint);
      closestIndex = Math.max(0, Math.min(closestIndex, insulinSensitivityI.length - 1));
      const closestValue = convertToMmol(insulinSensitivityI[closestIndex]);

      // only show tooltip if near data point
      const linePos = yScale(closestValue);
      const isNearLine = Math.abs(mouseYPosition - linePos) <= 50;
      if (isNearLine) {
        const tooltipXPos = Math.min(Math.max(mouseXPosition + 80, margin.left), width - margin.right - 80);
        const tooltipYPos = Math.min(Math.max(mouseYPosition + 80, margin.top), height - margin.bottom - 1);
        setTooltipData({
          hour: closestIndex,
          value: closestValue,
        });
        setTooltipPosition({
          x: tooltipXPos,
          y: tooltipYPos,
        });
      } else {
        setTooltipData({hour: 0, value: 0});
        setTooltipPosition(null);
      }
      setCrosshairTime(closestTimePoint);
    },
    [insulinSensitivityI, xScale, yScale],
  );

  const handleMouseLeave = useCallback(() => {
    setTooltipData({hour: 0, value: 0});
    setTooltipPosition(null);
    setCrosshairTime(null);
  }, [setCrosshairTime]);

  const crosshairXPosition = useMemo(() => (crosshairTime ? xScale(crosshairTime) : null), [crosshairTime, xScale]);

  const ChartLabel = styled('h5')(() => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 0,
    margin: 0,
  }));

  return (
    <Grid container item xs={12}>
      <Grid
        item
        xs={1}
        lg={0.5}
        sx={{
          padding: 0,
          margin: 0,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'flex-start',
        }}>
        <ChartLabel
          style={{
            writingMode: 'vertical-lr',
            transform: 'rotate(180deg)',
            whiteSpace: 'nowrap',
            position: 'relative',
            alignSelf: 'center',
          }}>
          {unit} per U
        </ChartLabel>
      </Grid>
      <Grid item xs={11} lg={11.5}>
        <div style={{position: 'relative'}}>
          <svg ref={svgRef} width="100%" height={height}>
            <Group>
              <AxisLeft
                scale={yScale}
                left={margin.left}
                tickFormat={d => (d as number).toFixed(1)}
                stroke="black"
                tickStroke="black"
                tickLabelProps={() => ({
                  fill: 'black',
                  fontSize: 11,
                  textAnchor: 'end',
                  dx: '-0.25em',
                  dy: '0.25em',
                })}
              />

              <AxisBottom
                top={height - margin.bottom}
                scale={xScale}
                stroke="black"
                tickStroke="black"
                numTicks={numTicks}
                tickFormat={d => d.toString()}
                tickLabelProps={() => ({
                  fill: 'black',
                  fontSize: 11,
                  textAnchor: 'middle',
                  dy: '0.25em',
                })}
              />

              <GridColumns
                top={margin.top}
                scale={xScale}
                numTicks={numTicks}
                height={height - margin.bottom - margin.top}
                stroke="#e0e0e0"
                strokeDasharray="5,5"
                pointerEvents="none"
                tickValues={gridColTicks}
              />

              <GridRows
                scale={yScale}
                width={width - margin.right - margin.left}
                strokeDasharray="2,2"
                left={margin.left}
                tickValues={gridRowTicks}
              />

              {insulinSensitivityI && (
                <LinePath
                  data={extendedValidInsulinSensitivityI}
                  x={(d, i) => xScale(i) || 0}
                  y={d => yScale(convertToMmol(d))}
                  stroke="#274C77"
                  strokeWidth={2}
                  curve={curveStepAfter}
                />
              )}
              {insulinSensitivityI &&
                validInsulinSensitivityI.map((d, i) => (
                  <Circle
                    key={`circle-insulinSensitivityI-${i}`}
                    cx={xScale(i) || 0}
                    cy={yScale(convertToMmol(d))}
                    r={3}
                    fill="#274C77"></Circle>
                ))}

              {insulinSensitivityII && insulinSensitivityII && (
                <LinePath
                  data={insulinSensitivityII}
                  x={(d, i) => xScale(i + 1) || 0}
                  y={d => yScale(d)}
                  stroke="green"
                  strokeWidth={2}
                  curve={curveStepAfter}
                />
              )}
              {insulinSensitivityII &&
                insulinSensitivityII.map((d, i) => (
                  <Circle
                    key={`circle-insulinSensitivityII-${i}`}
                    cx={xScale(i + 1) || 0}
                    cy={yScale(d)}
                    r={4}
                    fill="green"></Circle>
                ))}
              {/* Crosshair */}
              {crosshairXPosition !== null && (
                <line
                  x1={crosshairXPosition}
                  x2={crosshairXPosition}
                  y1={margin.top}
                  y2={height - margin.bottom}
                  stroke="gray"
                  strokeDasharray="4,4"
                />
              )}
              {/* Capture mouse events */}
              <rect
                x={margin.left}
                y={margin.top}
                width={width - margin.left - margin.right}
                height={height - margin.bottom}
                fill="transparent"
                onPointerMove={handlePointerMove}
                onPointerLeave={handleMouseLeave}
              />
            </Group>
          </svg>
          {/* Mouse hovering window */}
          {tooltipData && tooltipPosition && (
            <div
              style={{
                position: 'absolute',
                left: `${tooltipPosition.x}px`,
                top: `${tooltipPosition.y}px`,
                backgroundColor: 'white',
                padding: '5px 10px',
                pointerEvents: 'none',
                transform: 'translate(-50%, -100%)',
                boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)',
                borderRadius: '4px',
                textAlign: 'center',
                maxWidth: '160px',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}>
              <h5 style={{margin: 0, padding: 0, color: theme.palette.customColors?.lightGrey || 'black'}}>
                CF profile
              </h5>
              <p style={{fontWeight: 'bold', margin: 0, padding: 0}}>
                {tooltipData.value.toFixed(2)} {unit} per U
              </p>
              <p style={{fontSize: '12px', margin: 0, padding: 0}}>{'at hour ' + tooltipData.hour}</p>
            </div>
          )}
        </div>
      </Grid>
      <Grid
        item
        xs={12}
        display={'flex'}
        justifyContent="center"
        alignItems="flex-start"
        style={{marginTop: '1%', marginBottom: margin.bottom}}>
        <ChartLabel style={{position: 'absolute', marginLeft: margin.left}}>Time (h)</ChartLabel>
      </Grid>
    </Grid>
  );
};

export default InsulinSensitivityChart;
