import {useEffect} from 'react';
import {wsService} from '../api';

export const useWebSocket = (handler: (event: any) => void) => {
  useEffect(() => {
    if (!wsService.isConnected()) {
      wsService.connect();
    }
    wsService.listener(handler);

    return () => {
      wsService.removeListener(handler);
    };
  }, [handler]);
};
