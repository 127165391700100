import * as yup from 'yup';
import dayjs from 'dayjs';

import {fieldsErrorMessages} from '../constants';
import {ValidationSchemas} from '../interfaces/validation';
import moment from 'moment-timezone';
import {Theme} from '@mui/material';

export const getValidationSchema = (): ValidationSchemas => {
  const {NOT_VALID, PASSWORDS_DO_NOT_MATCH, REQUIRED} = fieldsErrorMessages;

  return {
    loginValidationSchema: yup.object({
      email: yup.string().required(REQUIRED).matches(RegExp('^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,}$'), NOT_VALID),
      password: yup
        .string()
        .required(REQUIRED)
        .min(8, NOT_VALID)
        .matches(RegExp('^[^ЁёА-я]+$'), NOT_VALID)
        .matches(RegExp('^(?=.*[A-Z]).*$'), NOT_VALID)
        .matches(RegExp('^(?=.*[a-z]).*$'), NOT_VALID)
        .matches(RegExp('^(?=.*\\d).*$'), NOT_VALID)
        .matches(
          RegExp(
            '^(?=.*[!@#$%^&*()\\[\\]{};:<>?+,.\\-\\/\\\\=|~])([a-zA-Z0-9!@#$%^&*()\\[\\]{};:<>?+,.\\-\\/\\\\=|~]*)$',
          ),
          NOT_VALID,
        )
        .matches(RegExp('^\\S+$'), NOT_VALID),
    }),
    forgotPasswordValidationSchema: yup.object({
      email: yup.string().required(REQUIRED).matches(RegExp('^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,}$'), NOT_VALID),
    }),
    createNewPasswordValidationSchema: yup.object({
      new: yup
        .string()
        .required(REQUIRED)
        .min(8, NOT_VALID)
        .matches(RegExp('^[^ЁёА-я]+$'), {name: 'onlyLatin', message: NOT_VALID})
        .matches(RegExp('^(?=.*[A-Z]).*$'), {name: 'oneUppercase', message: NOT_VALID})
        .matches(RegExp('^(?=.*[a-z]).*$'), {name: 'oneLowercase', message: NOT_VALID})
        .matches(RegExp('^(?=.*\\d).*$'), {name: 'oneNumber', message: NOT_VALID})
        .matches(
          RegExp(
            '^(?=.*[!@#$%^&*()\\[\\]{};:<>?+,.\\-\\/\\\\=|~])([a-zA-Z0-9!@#$%^&*()\\[\\]{};:<>?+,.\\-\\/\\\\=|~]*)$',
          ),
          {name: 'oneSpecialCharacter', message: NOT_VALID},
        )
        .matches(RegExp('^\\S+$'), {name: 'noSpaces', message: NOT_VALID}),
      repeat: yup
        .string()
        .required(REQUIRED)
        .oneOf([yup.ref('new')], PASSWORDS_DO_NOT_MATCH),
    }),
  };
};

export const {timeZone} = Intl.DateTimeFormat().resolvedOptions();

export const DateTimeAgo = (lastUpdate: string) => moment(new Date(lastUpdate)).tz(timeZone).fromNow();

export const changeFormateDateUTC = (oldDate: string) => {
  return moment(oldDate).utc().format('DD/MM/YYYY HH:mm:ss:SSS');
};
export const changeFormateDate = (oldDate: string) => {
  return moment(oldDate).format('DD/MM/YYYY HH:mm:ss:SSS');
};
export const changeFormateTime = (time: string) => {
  return moment(time).format('HH:mm');
};

export const localTimeZone = 'Europe/Zurich'; // export participant timeZone
export const currentLocalTime = new Date().toLocaleString('en-US', {timeZone: localTimeZone}); // local time of participant

// calculate timezone offset
export const getTimezoneOffset = (localDateTime: string, utcDateTime: string) => {
  const diffInMinutes = dayjs(localDateTime).diff(dayjs(utcDateTime), 'minute');
  return diffInMinutes / 60;
};

// check if two dates are on the same day
export const isSameDay = (localDateTime: string, comparisonDateTime: string) => {
  return dayjs(localDateTime).format('YYYY-MM-DD') === dayjs(comparisonDateTime).format('YYYY-MM-DD');
};

// format local time based on whether it's the same day as today
export const getLocalTimestamp = (localDateTime: string) => {
  const now = new Date().toLocaleString('en-US', {timeZone: localTimeZone});
  const isSameDayCheck = isSameDay(localDateTime, now);
  return isSameDayCheck ? dayjs(localDateTime).format('HH:mm') : dayjs(localDateTime).format('HH:mm (DD/MM/YYYY)');
};

// check if the time difference exceeds the given threshold
export const getIsTimeAgo = (localDateTime: string | undefined, thresholdMinutes: number, sign: boolean) => {
  const differece = Math.abs(
    dayjs(localDateTime).diff(new Date().toLocaleString('en-US', {timeZone: 'Europe/Zurich'}), 'minute'),
  );
  return sign ? differece > thresholdMinutes : differece < thresholdMinutes;
};

// determine time differece between given timestamp and now
export const getTimeAgo = (localDateTime: string): number => {
  return Math.abs(
    dayjs(localDateTime).diff(dayjs(new Date().toLocaleString('en-US', {timeZone: localTimeZone})), 'minute'),
  );
};

// determine icon color based on conditions
export const getIconColor = (dateTimeAgo: boolean | '' | undefined | null, theme: Theme) => {
  if (dateTimeAgo) return 'red';
  return theme.palette.primaryCustom[800];
};

export const getMode = (readingMode: string | undefined): {name: string; color: string} => {
  const colors: Record<string, string> = {
    O: '#EA9E8D',
    C: '#77ACA2',
  };
  const mode: {[key: string]: string} = {
    OPEN_LOOP: 'Open-loop',
    CLOSED_LOOP: 'Closed-loop',
  };

  const color = readingMode ? colors[readingMode?.charAt(0)] : '#000000';
  const name = readingMode ? mode[readingMode] : '--';
  return {name, color};
};
/*
function isBoolean(value: any): boolean {
  return typeof value === 'boolean';
}
*/
