import {measurementsService, participantsService} from '../api/services';

export const useRefetchData = (param: string, pollingInterval: number) => {
  // refetch CGM data
  const {
    data: latestCgmData,
    isLoading: isLoadingCgmData,
    refetch: refetchCgmData,
  } = measurementsService.useGetLatestCgmMeasurementsQuery(undefined, {pollingInterval});

  // refetch dynamic data
  const {
    data: latestDynamicData,
    isLoading: isLoadingDynamicData,
    refetch: refetchDynamicData,
  } = participantsService.useGetDynamicScreenDataQuery({params: {id: param}}, {pollingInterval});

  return {
    latestCgmData,
    latestDynamicData,
    isLoadingCgmData,
    isLoadingDynamicData,
    refetchCgmData,
    refetchDynamicData,
  };
};
