import {useTheme, Typography, Tooltip} from '@mui/material';
import {Icon, InlineLink, InsulinTrendIcon} from '../../../components';
import type {Participant, Measurement} from '../../../api/service-types';
import {unitSelector} from '../../../store/reducers/unitSlice';
import {useAppSelector} from '../../../store';
import {mmolPerLToMgPerdL} from '../../../helpers/formulas';
import {getIsTimeAgo, getLocalTimestamp, getIconColor, getMode} from '../../../utils';
import {IconProp} from '@fortawesome/fontawesome-svg-core';
import {faUserXmark} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  StyledContainer,
  StyledContent,
  StyledLastUpdate,
  StyledTimeValue,
  StyledValueContainer,
  StyledValue,
  StyledOperationMode,
} from './styles';

export type ParticipantCardProps = {
  participant: Participant;
  measurement?: Measurement;
};

export const ParticipantCard = ({participant, measurement}: ParticipantCardProps): JSX.Element => {
  const theme = useTheme();
  const {unit} = useAppSelector(unitSelector);

  const measurementValue =
    measurement?.measurement != null || (measurement?.controllerFilledValues?.length ?? 0) > 0
      ? mmolPerLToMgPerdL(
          measurement?.measurement != null
            ? measurement.measurement
            : measurement?.controllerFilledValues?.[measurement.controllerFilledValues.length - 1] ?? 0,
          unit,
        )
      : 0;

  const isInferred = measurement?.measurement == null && (measurement?.controllerFilledValues?.length ?? 0) > 0;
  const isTimeAgo = measurement ? getIsTimeAgo(measurement?.eventLocalDateTime, 6, true) : null;
  const localTimestamp = measurement ? getLocalTimestamp(measurement?.eventLocalDateTime) : null;

  const iconColor = measurement ? getIconColor(isTimeAgo, theme) : theme.palette.basic[600];
  let measurementColor;
  if (isInferred && measurement && !isTimeAgo) {
    measurementColor = 'transparent';
  } else if (measurement && isTimeAgo) {
    measurementColor = theme.palette.basic[600];
  } else {
    measurementColor = theme.palette.basic[1000];
  }

  return (
    <InlineLink to={`/dashboard/participants/${participant.id}`}>
      <StyledContainer>
        <Typography variant="bodySmall" color={theme.palette.basic[800]}>
          #{participant.id}
        </Typography>
        <Typography variant="h3" color={theme.palette.basic[1000]}>
          {participant.name}
          <Tooltip title="Test Participant" arrow>
            <FontAwesomeIcon
              icon={faUserXmark as IconProp}
              size="lg"
              color="red"
              style={{marginLeft: '8px', cursor: 'pointer'}}
            />
          </Tooltip>
        </Typography>
        <StyledContent>
          <StyledLastUpdate>
            <Typography variant="bodySmall" color={theme.palette.basic[800]}>
              Last update:
            </Typography>
            <StyledTimeValue>
              <Icon icon="check-rounded" size={20} color={iconColor} />
              <Typography variant="bodyBig" color={theme.palette.basic[1000]}>
                {localTimestamp ? localTimestamp : '--'}
              </Typography>
            </StyledTimeValue>
          </StyledLastUpdate>
          <StyledValueContainer>
            <StyledValue>
              <Typography
                sx={{
                  fontSize: '50px',
                  fontWeight: '700',
                  color: isTimeAgo ? `${theme.palette.basic[600]}` : `${theme.palette.basic[1000]}`,
                  WebkitTextStroke: isInferred && measurement && !isTimeAgo ? '1.5px' : 'none',
                  WebkitTextFillColor: measurementColor,
                  height: '67px',
                }}>
                {measurementValue ? measurementValue : '--'}
              </Typography>
              <Typography variant="h3" color={theme.palette.basic[1000]} padding="6.5px 0px">
                {unit}
              </Typography>
            </StyledValue>
            {(measurement?.trend || measurement?.trend == 0) && !isTimeAgo ? (
              <InsulinTrendIcon trend={measurement.trend} size={24} />
            ) : (
              <span style={{color: `${theme.palette.basic[1000]}`}}>{measurement ? '' : '--'}</span>
            )}
          </StyledValueContainer>
        </StyledContent>
        <StyledOperationMode>
          <StyledOperationMode>
            <Typography
              sx={{
                fontSize: '1rem',
                fontWeight: '700',
                color: !isTimeAgo ? getMode(measurement?.loopMode).color : `${theme.palette.basic[1000]}`,
                height: '1.6rem',
                textAlign: 'right',
              }}>
              {!isTimeAgo ? getMode(measurement?.loopMode).name : '--'}
            </Typography>
          </StyledOperationMode>
        </StyledOperationMode>
      </StyledContainer>
    </InlineLink>
  );
};
