/* eslint-disable no-nested-ternary */
import {
  useTheme,
  Typography,
  Box,
  CircularProgress,
  Tabs,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Tooltip,
  IconButton,
  TablePagination,
  useMediaQuery,
} from '@mui/material';
import {StyledContainer, StyledTablesContainer} from '../events/styles';
import React, {useEffect, useState, useRef} from 'react';
import {a11yProps, CustomTabPanel, LowerCaseTab} from '../data-tables';
import {EventResponse, participantsService} from '../../api';
import axios from 'axios';
import {ENDPOINT_TIME_OPTIONS, httpsUrl} from '../../constants';
import {Icon, customToast} from '../../components';
import {changeFormateDate, changeFormateDateUTC} from '../../utils';
import {useWebSocket} from '../../hooks/useWebSocket';
import {ToastContainer} from 'react-toastify';

export type LogData = {
  id: number;
  participantId: number;
  androidId: string;
  level: string;
  tag: string;
  message: string;
  logUTCInstant: string;
  logParticipantLocalDateTime: string;
  shouldHighlight: boolean;
};

export type LogResponse = {
  totalCount: number;
  currentStart: number;
  nextStart: number;
  timeframe: string;
  data: LogData[];
};

export const LogsPage = () => {
  const theme = useTheme();
  const containerRef = useRef<HTMLDivElement | null>(null);
  const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));

  const [value, setValue] = useState(0);
  const [tabParticipant, setTabParticipant] = useState<number | null>(1);
  const [responseData, setResponseData] = useState<LogResponse | null>(null);
  const [timeFrame, setTimeFrame] = useState<string>(ENDPOINT_TIME_OPTIONS.HOUR);
  const [isLoading, setIsLoading] = useState(false);

  const [page, setPage] = useState(0);
  const [currentStart, setCurrentStart] = useState(0);
  const [limit, setLimit] = useState(250);
  const [nextStart, setNextStart] = useState(limit);

  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);

  const {
    data: participants,
    isLoading: areParticipantsLoading,
    error: participantsError,
    refetch: refetchParticipants,
  } = participantsService.useGetParticipantsQuery();

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    if (newPage < page) {
      setNextStart(currentStart);
      setCurrentStart(currentStart - limit);
    } else {
      setCurrentStart(nextStart);
      setNextStart(nextStart + limit);
    }

    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setLimit(parseInt(event.target.value));
    setNextStart(parseInt(event.target.value));
    setCurrentStart(0);
    setPage(0);
  };

  useWebSocket((event: EventResponse) => {
    if (event.priority === 3) customToast({event});
  });

  useEffect(() => {
    if (tabParticipant !== null && tabParticipant !== undefined) {
      setIsLoading(true);
      axios
        .get<LogResponse>(
          `${httpsUrl}/logging?participant_id=${tabParticipant}&timeframe=${timeFrame}&start=${currentStart}&limit=${limit}`,
        )
        .then(response => {
          setResponseData(response.data);
        })
        .catch(error => {
          console.error('Error fetching logs:', error);
          setResponseData(null);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [tabParticipant, timeFrame, currentStart, nextStart, limit]);

  const handleMouseDown = (e: React.MouseEvent) => {
    setIsDragging(true);
    setStartX(e.pageX);
    setScrollLeft(containerRef.current?.scrollLeft || 0);
    e.preventDefault();
  };

  const handleMouseMove = (e: React.MouseEvent) => {
    if (!isDragging) return;
    const distance = e.pageX - startX;
    if (containerRef.current) {
      window.requestAnimationFrame(() => {
        containerRef.current!.scrollLeft = scrollLeft - distance;
      });
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleMouseLeave = () => {
    setIsDragging(false);
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    const selectedParticipant = participants ? participants[newValue] : null;
    if (selectedParticipant && selectedParticipant.id) {
      setTabParticipant(selectedParticipant.id);
    }
  };
  const handleChangeTimeFrame = (event: SelectChangeEvent) => {
    setTimeFrame(event?.target?.value as string);
  };

  const onRefetch = async () => {
    refetchParticipants();
  };

  if (areParticipantsLoading) {
    return (
      <div style={{width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
        <CircularProgress size={40} />
      </div>
    );
  }
  if (participantsError) {
    return (
      <div
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        <h1>Error</h1>
        <button onClick={onRefetch}>Reload data</button>
      </div>
    );
  }

  return (
    <StyledTablesContainer>
      <ToastContainer limit={10} />
      <Typography variant="h2" color={theme.palette.basic[1000]}>
        Logs
      </Typography>

      <StyledContainer>
        <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
          <Tabs
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example">
            {participants &&
              participants.map((participant, index) => (
                <LowerCaseTab key={`logsParticipant${participant.id}`} label={participant.name} {...a11yProps(index)} />
              ))}
          </Tabs>
        </Box>
        {participants &&
          participants.map((participant, index) => (
            <CustomTabPanel key={participant.id} value={value} index={index}>
              <Box
                sx={{
                  marginTop: 2,
                  marginBottom: 4,
                  display: 'flex',
                  flexDirection: {xs: 'column', sm: 'column', md: 'row'},
                  gap: '2rem',
                  justifyContent: 'space-between',
                }}>
                <FormControl fullWidth sx={{flex: 1, maxWidth: 500}}>
                  <InputLabel id="demo-simple-select-label" sx={{'&.Mui-focused': {color: theme.palette.basic[1000]}}}>
                    Time frame
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={timeFrame}
                    label="Time frame"
                    onChange={handleChangeTimeFrame}>
                    <MenuItem value={ENDPOINT_TIME_OPTIONS.HOUR}>1 Hour</MenuItem>
                    <MenuItem value={ENDPOINT_TIME_OPTIONS.THREE_HOURS}>3 Hours</MenuItem>
                    <MenuItem value={ENDPOINT_TIME_OPTIONS.SIX_HOURS}>6 Hours</MenuItem>
                    <MenuItem value={ENDPOINT_TIME_OPTIONS.EIGHT_HOURS}>8 Hours</MenuItem>
                    <MenuItem value={ENDPOINT_TIME_OPTIONS.TEN_HOURS}>10 Hours</MenuItem>
                    <MenuItem value={ENDPOINT_TIME_OPTIONS.TWELVE_HOURS}>12 Hours</MenuItem>
                    <MenuItem value={ENDPOINT_TIME_OPTIONS.SIXTEEN_HOURS}>16 Hours</MenuItem>
                    <MenuItem value={ENDPOINT_TIME_OPTIONS.DAY}>24 Hours</MenuItem>
                    <MenuItem value={ENDPOINT_TIME_OPTIONS.THREE_DAYS}>3 Days</MenuItem>
                    <MenuItem value={ENDPOINT_TIME_OPTIONS.WEEK}>1 Week</MenuItem>
                    <MenuItem value={ENDPOINT_TIME_OPTIONS.TWO_WEEKS}>2 Weeks</MenuItem>
                    <MenuItem value={ENDPOINT_TIME_OPTIONS.MONTH}>1 Month</MenuItem>
                    <MenuItem value={ENDPOINT_TIME_OPTIONS.ALL_TIME}>All time</MenuItem>
                  </Select>
                </FormControl>

                <TablePagination
                  component="div"
                  count={responseData?.totalCount ? responseData?.totalCount : 0}
                  page={page}
                  onPageChange={handleChangePage}
                  rowsPerPage={limit}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  rowsPerPageOptions={[100, 250, 500, 1000]}
                  sx={{flex: 1, alignSelf: {xs: 'flex-start', sm: 'flex-start', md: 'flex-end'}}}
                />
              </Box>
              <div
                ref={containerRef}
                style={{
                  display: 'grid',
                  gap: '0m',
                  overflowY: 'hidden',
                  padding: 0,
                  marginRight: '-30px',
                  marginLeft: '-30px',
                  paddingBottom: '5px',
                }}
                onMouseDown={handleMouseDown}
                onMouseMove={handleMouseMove}
                onMouseUp={handleMouseUp}
                onMouseLeave={handleMouseLeave}>
                <div
                  style={{
                    fontWeight: 'bold',
                    display: 'flex',
                    gridColumn: '1 / -1',
                    borderBottom: '1px solid #cccc',
                    paddingBottom: '10px',
                  }}>
                  <div style={{marginLeft: '34px'}}>
                    {isMediumScreen ? (
                      <>
                        Participant <br /> local time
                      </>
                    ) : (
                      'Participant time'
                    )}
                  </div>
                  <div style={{marginLeft: isMediumScreen ? '1.5rem' : '6rem'}}>Message</div>
                </div>

                {isLoading ? (
                  <div style={{textAlign: 'center', padding: 0}}>
                    <CircularProgress size={24} />
                  </div>
                ) : responseData?.data.length ? (
                  responseData.data.map(log => (
                    <div
                      key={log.id}
                      style={{
                        display: 'grid',
                        gap: '0',
                        gridTemplateColumns: isMediumScreen ? '35px 110px auto' : '35px 220px auto',
                        alignItems: 'start',
                        fontWeight: log.shouldHighlight ? 'bold' : 'normal',
                        color: log.shouldHighlight ? theme.palette.primary.main : '#000000',
                        padding: '0',
                        marginLeft: '-10px',
                        borderBottom: 'none',
                        width: '100%',
                      }}>
                      {/* Icon */}
                      <div
                        style={{
                          flexShrink: 0,
                          display: 'flex',
                          alignItems: 'center',
                          padding: '0 10px',
                          textAlign: 'right',
                          marginTop: '-4px',
                        }}>
                        <Tooltip
                          title={
                            <Box sx={{display: 'flex', flexDirection: 'column', gap: 1}}>
                              {[
                                {label: 'Log ID', value: log.id},
                                {label: 'Android ID', value: log.androidId},
                                {label: 'Participant ID', value: log.participantId},
                                {label: 'Level', value: log.level},
                                {label: 'Tag', value: log.tag},
                                {label: 'UTC time', value: changeFormateDateUTC(log.logUTCInstant)},
                              ].map((item, index) => (
                                <Box key={index} sx={{display: 'flex', gap: 1}}>
                                  <Typography variant="body2" sx={{fontWeight: 800}}>
                                    {item.label}:
                                  </Typography>
                                  <Typography variant="body2">{item.value}</Typography>
                                </Box>
                              ))}
                            </Box>
                          }
                          arrow>
                          <div style={{position: 'relative'}}>
                            <IconButton>
                              <Icon
                                icon="info"
                                size={16}
                                color={log.shouldHighlight ? theme.palette.primary.main : theme.palette.basic[500]}
                              />
                            </IconButton>
                          </div>
                        </Tooltip>
                      </div>
                      <div style={{flexShrink: 0, padding: '0 10px'}}>
                        {changeFormateDate(log.logParticipantLocalDateTime)}
                      </div>
                      <div style={{flexGrow: 1, textAlign: 'left', padding: '0 10px'}}> {log.message} </div>
                    </div>
                  ))
                ) : (
                  <div style={{textAlign: 'center', padding: '0'}}>No logs available</div>
                )}
              </div>
            </CustomTabPanel>
          ))}
      </StyledContainer>

      <TablePagination
        component="div"
        count={responseData?.totalCount ? responseData?.totalCount : 0}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={limit}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={[100, 250, 500, 1000]}
      />
    </StyledTablesContainer>
  );
};
