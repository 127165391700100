import {BrowserRouter} from 'react-router-dom';
import {ErrorBoundary} from 'react-error-boundary';

import {ScrollToTop, ErrorFallback} from '../src/components';
import {Router} from '../src/routes';
import {ThemeProvider} from '../src//theme';
import {useEffect} from 'react';
import {wsService} from './api';
function App() {
  useEffect(() => {
    return () => {
      wsService.disconnect(); // only disconnect when app fully unmounts
    };
  }, []);

  return (
    <BrowserRouter>
      <ThemeProvider>
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <ScrollToTop />
          <Router />
        </ErrorBoundary>
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;
