import {styled} from '@mui/material/styles';

export const StyledContainer = styled('div')(({theme}) => ({
  padding: '12px 24px',
  borderRadius: '12px',
  boxShadow: theme.customShadows.around,
  display: 'flex',
  flexDirection: 'column',
  height: 'auto',
  margin: '2rem 0',
  '@media (max-width: 700px)': {
    padding: 0,
    marging: 0,
  },
}));

// TODO: re-use this for all sections since it has the same css
