import moment from 'moment';
import {DynamicData, MealEvents} from '../../../../src/api';

export const timeSampConvert = (timeSamp: string, timeZone: string) => {
  const formattedTime = moment(timeSamp, moment.ISO_8601); // Ensure it parses as ISO
  return formattedTime.isValid() ? formattedTime.tz(timeZone).format('YYYY-MM-DDTHH:mm:ss') : '--';
};

export const getTimeDomain = (selectedDateRange: string, currentTime: string): [number, number] => {
  const timeFrameMap: {[key: string]: number} = {
    '1h': 1 * 60 * 60 * 1000,
    '3h': 3 * 60 * 60 * 1000,
    '6h': 6 * 60 * 60 * 1000,
    '8h': 8 * 60 * 60 * 1000,
    '10h': 10 * 60 * 60 * 1000,
    '12h': 12 * 60 * 60 * 1000,
    '16h': 16 * 60 * 60 * 1000,
    day: 24 * 60 * 60 * 1000,
    'three-days': 3 * 24 * 60 * 60 * 1000,
    week: 7 * 24 * 60 * 60 * 1000,
    'two-weeks': 14 * 24 * 60 * 60 * 1000,
    month: 30 * 24 * 60 * 60 * 1000,
    all: 90 * 24 * 60 * 60 * 1000, // 2 months (temporary value)
  };
  // convert to european timezone
  const time = new Date(timeSampConvert(currentTime, 'Europe/Zurich')).getTime(); // TODO: make it dynamic (second release)
  // default to '3h' if not found in the map
  const range = timeFrameMap[selectedDateRange] || timeFrameMap['3h'];

  const minX = time - range;
  const maxX = time;

  return [minX, maxX];
};

export const getInitialMeal = (mealEvents: MealEvents[] | undefined): number | undefined => {
  if (mealEvents?.length == 0) {
    return undefined;
  }
  const validMeal = mealEvents?.find(meal => !meal.isHypoTreatment);
  return validMeal ? validMeal?.carbohydratesEstimate : undefined;
};

// helper function to update meal state based on latest dynamic data
export const updateMeal = (latestDynamicData: DynamicData, prevMeal: any) => {
  if (
    latestDynamicData?.lastMeal &&
    (latestDynamicData?.lastMealBolus?.deliveryStatus == 'DELIVERED' ||
      latestDynamicData?.lastMealBolus?.deliveryStatus == 'PARTIALLY_DELIVERED') &&
    latestDynamicData?.lastMealBolus?.injectedValue !== null &&
    !latestDynamicData?.lastMeal?.isHypoTreatment
  ) {
    const {eventLocalDateTime, carbohydratesEstimate} = latestDynamicData?.lastMeal;
    const eventLocalDateTimeBolus = latestDynamicData?.lastMealBolus?.eventLocalDateTime;
    const injectedValue = latestDynamicData?.lastMealBolus.injectedValue ?? 0;

    const newMeal = {
      timestampCarbs: eventLocalDateTime || prevMeal?.timestampCarbs,
      timestampBolus: eventLocalDateTimeBolus || prevMeal?.timestampBolus,
      amount: carbohydratesEstimate || prevMeal?.amount,
      value: injectedValue ?? prevMeal?.value,
    };

    if (
      newMeal.timestampCarbs != prevMeal?.timestampCarbs ||
      newMeal.timestampBolus != prevMeal?.timestampBolus ||
      newMeal.amount !== prevMeal.amount ||
      newMeal.value !== prevMeal.value
    ) {
      return newMeal; // only update if new meals are different
    }
  }
  return prevMeal; // return old meal if no changes
};

/* helper functions to calculate total microbolus/ basal insulin over last two hours */
const SLOT_DURATION_MS = 5 * 60 * 1000; // 5 minutes in ms
const TIME_FRAME_MS = 2 * 60 * 60 * 1000; // 2 hours in ms
const SLOT_COUNT = 24; // number of 5-minute slots in 2 hours

// helper function to get time slots for last 2 hours based on basal profile
const getTimeSlots = (profile: number[], currentTime: Date): Map<number, number> => {
  const timeSlots = new Map();
  const twoHoursAgoInMs = currentTime.getTime() - TIME_FRAME_MS;
  for (let i = 0; i < SLOT_COUNT; i++) {
    const timeSlot = twoHoursAgoInMs + i * SLOT_DURATION_MS;
    const staticIndex = Math.floor(new Date(timeSlot).getHours() % 24);
    timeSlots.set(timeSlot, profile[staticIndex] || 0);
  }
  return timeSlots;
};

// helper function to calculate bolus total for last 2 hours
const calculateBoluses = (
  boluses: any[],
  twoHoursAgo: Date,
  earliestTimepoint: Date,
): {
  total: number;
  earliestTimepoint: Date;
} => {
  let total = 0;

  boluses.forEach(item => {
    if (
      !item.eventLocalDateTime ||
      item.deliveryStatus !== 'DELIVERED' ||
      item.deliveryStatus !== 'PARTIALLY_DELIVERED' ||
      item.injectedValue == null
    )
      return;
    const eventTime = new Date(item.eventLocalDateTime);
    if (eventTime >= twoHoursAgo && item.basalSafetyNetAmount == null && item.correctionBolusSafetyNetAmount == null) {
      total += item.injectedValue;
      earliestTimepoint = eventTime < earliestTimepoint ? eventTime : earliestTimepoint;
    }
  });
  return {total, earliestTimepoint};
};

// helper function to handle microboluses and update time slots
const handleMicroboluses = (
  microboluses: any[],
  timeSlots: Map<number, number>,
  twoHoursAgo: Date,
  currentTime: Date,
): Map<number, number> => {
  microboluses
    .slice()
    .reverse()
    .forEach(item => {
      if (
        !item.eventLocalDateTime ||
        item.deliveryStatus !== 'DELIVERED' ||
        item.deliveryStatus !== 'PARTIALLY_DELIVERED' ||
        item.injectedValue == null
      )
        return;

      const eventTime = new Date(item.eventLocalDateTime);

      if (
        eventTime > twoHoursAgo &&
        eventTime <= currentTime &&
        item.manualBolusSafetyNetAmount == null &&
        item.mealBolusPassedSafetyNet == null
      ) {
        let nextKey: number | null = null;

        timeSlots.forEach((value, key, map) => {
          const keysArray = Array.from(map.keys());
          const currentIndex = keysArray.indexOf(key);
          nextKey = currentIndex + 1 < keysArray.length ? keysArray[currentIndex + 1] : currentTime.getTime();

          if (nextKey != null && eventTime.getTime() >= key && eventTime.getTime() < nextKey) {
            timeSlots.set(key, item.injectedValue);
          }
        });
      }
    });
  return timeSlots;
};

// main function calculating total insuline over last two hours
export const calculateLastHoursInsulinTotal = (latestDynamicData: any, profile: number[]) => {
  const currentTime = new Date(new Date().toLocaleString('en-US', {timeZone: 'Europe/Zurich'}));
  const twoHoursAgo = new Date(currentTime.getTime() - TIME_FRAME_MS);
  let total = 0;
  let earliestTimepoint = currentTime;

  // get the time slots for the last 2 hours based on basal profile
  const timeSlots = getTimeSlots(profile, currentTime);

  // calculate bolus total and update earliest timepoint
  const {total: bolusTotal, earliestTimepoint: bolusEarliestTimepoint} = calculateBoluses(
    latestDynamicData.boluses,
    twoHoursAgo,
    earliestTimepoint,
  );

  total += bolusTotal;
  earliestTimepoint = bolusEarliestTimepoint;

  // handle microboluses and update time slots
  const updatedTimeSlots = handleMicroboluses(latestDynamicData.microboluses, timeSlots, twoHoursAgo, currentTime);

  // add microbolus values to total and track earliest timepoint
  updatedTimeSlots.forEach((value, key) => {
    total += value;
    if (key < earliestTimepoint.getTime()) {
      earliestTimepoint = new Date(key);
    }
  });
  return {total: total || 0.001, earliestTimepoint};
};
