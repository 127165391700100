import {BasalProfile, DynamicData} from '../../../api';
import React, {useRef, useEffect, useState, useMemo} from 'react';
import {scaleLinear, scaleTime} from '@visx/scale';
import {AxisBottom, AxisLeft} from '@visx/axis';
import {Group} from '@visx/group';
import GridColumns from '@visx/grid/lib/grids/GridColumns';
import GridRows from '@visx/grid/lib/grids/GridRows';
import {curveStepAfter, line} from 'd3-shape';
import {Tooltip, Box, FormControlLabel, Grid, IconButton, Switch, Typography, useTheme} from '@mui/material';
import styled from '@emotion/styled';
import {Circle, LinePath} from '@visx/shape';
import moment from 'moment';
import {Icon} from '../../../components';

import {
  calculateInsuline,
  getEventPosition,
  splitDataIntoSegments,
  combineMicrobolusAndBasalData,
  getBasalValueForMicrobolusData,
} from '../utils';

interface DataPoint {
  x: Date;
  y: number;
}

interface InsulinAndMealsChartProps {
  microboluses: DynamicData['microboluses'];
  controlModeChanges: DynamicData['controlModeChanges'];
  crosshairTime: number | null;
  setCrosshairTime: (time: number | null) => void;
  basalProfiles: BasalProfile[];
  selectedDateRange: string;
  minX: number;
  maxX: number;
  TDI: number | null;
}

export const InsulinAndMealsChart = ({
  microboluses,
  controlModeChanges,
  crosshairTime,
  setCrosshairTime,
  selectedDateRange,
  basalProfiles,
  minX,
  maxX,
  TDI,
}: InsulinAndMealsChartProps): JSX.Element => {
  const [tooltipData, setTooltipData] = useState<DataPoint | null>(null);
  const [tooltipPosition, setTooltipPosition] = useState<{x: number; y: number} | null>(null);
  const [width, setWidth] = useState(1000);
  const [isUnit, setIsUnit] = useState(true);
  const [tooltipTitle, setTooltipTitle] = useState<string>('');
  const theme = useTheme();

  const microbolusesData: DataPoint[] = microboluses
    .filter(event => {
      const eventTime = new Date(event.eventLocalDateTime).getTime();
      return eventTime >= minX && eventTime <= maxX && event.deliveryStatus === 'DELIVERED';
    })
    .map(micro => {
      const {mb} = calculateInsuline(
        micro.injectedValue,
        micro.basalSafetyNetAmount,
        micro.correctionBolusSafetyNetAmount,
        micro.loopMode,
      );
      if (mb != null) {
        return {
          x: new Date(micro.eventLocalDateTime),
          y: mb * 12,
        };
      }
    })
    .filter((item): item is DataPoint => item !== undefined && item !== null);

  const basalData = basalProfiles.length > 0 && basalProfiles[0]?.profile ? basalProfiles[0].profile : [];
  const manualModeChanges =
    controlModeChanges &&
    controlModeChanges?.filter(change => {
      return (change.loopMode === 'OPEN_LOOP' && change.isAutomaticChange === false) ?? [];
    });
  const maxTDI = TDI ? (6 * TDI) / (2 * 24) : Infinity;

  const {combinedData, cappedCombinedData, basalSegments} = useMemo(() => {
    // to segments based on missing data
    const microbolusSegments = splitDataIntoSegments(microbolusesData, manualModeChanges, basalData, maxX);
    // create merged profile from microbolus and basal data
    const combinedData = combineMicrobolusAndBasalData(microbolusSegments, basalData, minX, maxX);
    // create segments of basal profile based on microbolus data
    const basalSegments = getBasalValueForMicrobolusData(microbolusSegments, basalData);
    // create merged profile from microbolus and basal data
    const cappedCombinedData =
      maxTDI !== Infinity ? combinedData.map(d => ({...d, y: Math.min(d.y, maxTDI)})) : combinedData;
    return {combinedData, cappedCombinedData, basalSegments};
  }, [microbolusesData, basalData]);

  const svgRef = useRef<SVGSVGElement>(null);

  const minY = 0;
  const maxYBasal = Math.max(...basalData.map(d => d));
  const maxY = combinedData ? Math.min(Math.max(...combinedData.map(d => d.y), maxYBasal), maxTDI) : maxYBasal;
  const height = 250;
  const margin = {top: 20, right: 20, bottom: 30, left: 40};

  useEffect(() => {
    if (svgRef.current) {
      setWidth(svgRef.current.clientWidth);
    }

    const handleResize = () => {
      if (svgRef.current) {
        setWidth(svgRef.current.clientWidth);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const xScale = scaleTime({
    domain: [minX, maxX],
    range: [margin.left, width - margin.right],
  });

  const yScale = scaleLinear({
    domain: [minY, maxY],
    range: [height - margin.bottom, margin.top],
  });

  // y axis tick formatting
  const calculateYTicks = () => {
    const [yMin, yMax] = yScale.domain();
    const yTicks = yScale.ticks();
    const yTickDist = yTicks[yTicks.length - 1] - yTicks[yTicks.length - 2];
    const yTickPaddingTop = yTicks[yTicks.length - 1] + yTickDist - yMax;
    const yTickPaddingBottom = yMin - yTicks[0] + yTickDist;
    const yTickBottom = parseFloat((yMin - yTickPaddingBottom).toFixed(2));
    const yTickTop = parseFloat((yMax + yTickPaddingTop).toFixed(2));
    return [yTickBottom, yTickTop];
  };
  const [yTickBottom, yTickTop] = calculateYTicks();
  yScale.domain([yTickBottom, yTickTop]);

  // adjust tick number based on screen width
  const numTicks = useMemo(() => {
    return Math.floor(width / 75);
  }, [width]);

  const lineGenerator = line<DataPoint>()
    .x(d => xScale(d.x))
    .y(d => yScale(d.y))
    .curve(curveStepAfter);

  const handlePointerMove = (event: React.PointerEvent<SVGElement>) => {
    const svgRect = svgRef.current?.getBoundingClientRect();
    if (!svgRect) return;

    const {x: mouseXPosition, y: mouseYPosition} = getEventPosition(event, svgRect);
    const timeAtMousePosition = xScale.invert(mouseXPosition).getTime();

    const closestMicrobolus =
      cappedCombinedData.length > 0
        ? cappedCombinedData.reduce((prev, curr) => {
            const prevDistance = Math.abs(xScale(prev.x) - mouseXPosition);
            const currDistance = Math.abs(xScale(curr.x) - mouseXPosition);
            return currDistance < prevDistance ? curr : prev;
          })
        : null;

    const closestBasalProfile =
      basalSegments.length > 0
        ? basalSegments.flat().reduce((prev, curr) => {
            const prevDistance = Math.abs(xScale(prev.x) - mouseXPosition);
            const currDistance = Math.abs(xScale(curr.x) - mouseXPosition);
            return currDistance < prevDistance ? curr : prev;
          })
        : null;

    const originalPoint = closestMicrobolus
      ? combinedData.find(d => d.x.getTime() === closestMicrobolus.x.getTime())
      : null;
    const isCapped = originalPoint && originalPoint.y > maxTDI;

    let closestDatum: DataPoint | null = null;
    if (closestMicrobolus || closestBasalProfile) {
      // calculate distance to nearest point in OL basal segment
      const basalProfileXDistance = closestBasalProfile
        ? Math.abs(mouseXPosition - xScale(closestBasalProfile.x))
        : Infinity;
      // if segment to far away along the x-axis only consider combined data
      if (basalProfileXDistance > 5) {
        closestDatum = closestMicrobolus;
      } else {
        const microbolusYDistance = closestMicrobolus
          ? Math.abs(mouseYPosition - (isUnit ? yScale(closestMicrobolus.y) : yScale(closestMicrobolus.y / 12)))
          : Infinity;
        const basalProfileYDistance = closestBasalProfile
          ? Math.abs(mouseYPosition - (isUnit ? yScale(closestBasalProfile.y) : yScale(closestBasalProfile.y / 12)))
          : Infinity;
        // compare which bolus data point is closer to mouse based on y axis
        closestDatum = microbolusYDistance < basalProfileYDistance ? closestMicrobolus : closestBasalProfile;
      }
    }

    const tooltipTitle = closestDatum === closestMicrobolus ? 'Microbolus' : 'OL basal profile';
    const tooltipData = isCapped && originalPoint && tooltipTitle == 'Microbolus' ? originalPoint : closestDatum;

    // if data out of bound, set crosshair to null (basalprofile)
    if (timeAtMousePosition < minX || timeAtMousePosition > maxX) {
      setCrosshairTime(null);
      return;
    }
    setCrosshairTime(timeAtMousePosition);
    if (!closestDatum) {
      setTooltipData(null);
      setTooltipPosition(null);
      return;
    }

    const tooltipXPos = Math.min(Math.max(mouseXPosition + 1, margin.left + 1), width - margin.right - 100);
    const tooltipYPos = Math.min(
      Math.max((isUnit ? yScale(closestDatum.y) : yScale(Math.round((closestDatum.y / 12) * 100) / 100)) - 80),
      height - margin.bottom - 1,
    );

    setTooltipTitle(tooltipTitle);
    setTooltipData(tooltipData);
    setTooltipPosition({
      x: tooltipXPos,
      y: tooltipYPos,
    });
  };

  const handlePointerLeave = () => {
    setTooltipData(null);
    setTooltipPosition(null);
    setCrosshairTime(null);
  };

  const ChartLabel = styled('h5')(() => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 0,
    padding: 0,
    width: '100%',
    writingMode: 'vertical-lr',
    transform: 'rotate(180deg)',
    whiteSpace: 'nowrap',
    position: 'relative',
    left: '50%',
    transformOrigin: 'center center',
  }));

  // function to modify data if unit U
  const modifyDataForUnit = (data: DataPoint[]): DataPoint[] => {
    const dataPoints = [...combinedData.map(d => d.y / 12), ...basalSegments.flat().map(d => d.y / 12)];
    // calculate new min and max values for y-axis
    const minY = Math.min(...dataPoints);
    const maxY = Math.max(...dataPoints);
    // handle constant value over time
    if (minY === maxY) {
      const padding = maxY * 0.1; // add padding
      yScale.domain([minY - padding, maxY + padding]);
    } else {
      yScale.domain([minY, maxY]);
    }
    // update tick values
    const [yTickBottom, yTickTop] = calculateYTicks();
    yScale.domain([yTickBottom, yTickTop]);

    return data.map(d => ({...d, y: Math.round((d.y / 12) * 100) / 100}));
  };
  // responsive circle size
  const circleSize = selectedDateRange === '1h' || selectedDateRange === '3h' ? 3 : 2;
  // render circles without maxX
  const circlePoints =
    cappedCombinedData.length > 1
      ? cappedCombinedData.slice(
          0,
          cappedCombinedData[cappedCombinedData.length - 1].x.getTime() -
            cappedCombinedData[cappedCombinedData.length - 2].x.getTime() <=
            1 * 60 * 1000
            ? -1
            : cappedCombinedData.length - 1,
        )
      : cappedCombinedData;

  return (
    <div>
      <FormControlLabel
        style={{paddingLeft: 10}}
        control={<Switch checked={isUnit} onChange={() => setIsUnit(!isUnit)} />}
        label={isUnit ? 'U/h' : 'U'}
      />
      <Grid item container xs={12} mt={2}>
        <Grid item xs={1} lg={0.5} position={'relative'} display={'flex'} justifyContent="center" marginLeft="auto">
          <ChartLabel> {isUnit ? 'U/h' : 'U'} </ChartLabel>
          <Box sx={{cursor: 'default', position: 'relative', left: '-40%'}}>
            <Tooltip
              arrow
              title={
                <>
                  <Typography variant="h6" gutterBottom>
                    Basal insulin infusion
                  </Typography>
                  <Typography variant="body2">
                    Open-loop insulin basal profile (gray line) and closed-loop insulin microbolus infusion.
                  </Typography>
                </>
              }>
              <IconButton size="small" sx={{pointerEvents: 'auto', cursor: 'inherit'}}>
                <Icon icon="info" size={16} color={theme.palette.basic[500]} />
              </IconButton>
            </Tooltip>
          </Box>
        </Grid>
        <Grid item xs={11} lg={11.5}>
          <div style={{position: 'relative'}}>
            <svg
              ref={svgRef}
              width="100%"
              height={height}
              onPointerMove={handlePointerMove}
              onPointerLeave={handlePointerLeave}
              pointerEvents="all">
              <defs>
                <linearGradient
                  id="microbolus-gradient"
                  x1="0"
                  y1="0"
                  x2="1"
                  y2="0"
                  spreadMethod="pad"
                  gradientTransform={`translate(${margin.left}, 0) scale(${width - margin.left - margin.right}, 1)`}>
                  <stop offset="0%" stopColor="#03D4FD" />
                  <stop offset="100%" stopColor="#0AD3CD" />
                </linearGradient>
              </defs>

              <rect width={width} height={height} rx={14} fill={'#fff'} />

              <Group>
                {/* Grid */}
                <GridColumns
                  scale={xScale}
                  top={margin.top}
                  height={height - margin.bottom - margin.top}
                  stroke="#e0e0e0"
                  strokeDasharray="5,5"
                  pointerEvents="none"
                />
                <GridRows
                  scale={yScale}
                  width={width - margin.right - margin.left}
                  strokeDasharray="4"
                  left={margin.left}
                />
                <AxisBottom
                  top={height - margin.bottom}
                  scale={xScale}
                  numTicks={numTicks}
                  tickLabelProps={() => ({
                    fill: 'black',
                    fontSize: 11,
                    textAnchor: 'middle',
                  })}
                  tickFormat={d => {
                    const date = d instanceof Date ? d : new Date(d.valueOf());
                    return date.toLocaleTimeString('en-EU', {
                      hour: '2-digit',
                      minute: '2-digit',
                      hour12: false,
                    });
                  }}
                />
                <AxisLeft
                  scale={yScale}
                  left={margin.left}
                  tickLabelProps={() => ({
                    fill: 'black',
                    fontSize: 11,
                    textAnchor: 'end',
                  })}
                />
              </Group>

              {/* Microbolus */}
              {cappedCombinedData && (
                <LinePath
                  d={lineGenerator(isUnit ? cappedCombinedData : modifyDataForUnit(cappedCombinedData)) || undefined}
                  fill="none"
                  stroke="#03D4FD"
                  strokeWidth={2}
                />
              )}
              {isUnit
                ? circlePoints.map((d, i) => (
                    <Circle
                      key={`microbolus-dot-${i}`}
                      cx={xScale(d.x)}
                      cy={yScale(d.y)}
                      r={circleSize}
                      fill="#03D4FD"
                    />
                  ))
                : modifyDataForUnit(circlePoints).map((d, i) => (
                    <Circle
                      key={`microbolus-dot-${i}`}
                      cx={xScale(d.x)}
                      cy={yScale(d.y)}
                      r={circleSize}
                      fill="#03D4FD"
                    />
                  ))}

              {/* Basal profile */}
              {basalSegments.map((segment, index) => (
                <LinePath
                  key={`basal-segment-${index}`}
                  d={lineGenerator(isUnit ? segment : modifyDataForUnit(segment)) || undefined}
                  fill="none"
                  stroke="#8B8C89"
                  strokeWidth={2}
                />
              ))}

              {/* Crosshair */}
              {crosshairTime !== null && (
                <line
                  x1={xScale(new Date(crosshairTime))}
                  x2={xScale(new Date(crosshairTime))}
                  y1={margin.top}
                  y2={height - margin.bottom}
                  stroke="gray"
                  strokeDasharray="4,4"
                />
              )}

              {tooltipData && (
                <Circle
                  cx={xScale(tooltipData.x)}
                  cy={isUnit ? yScale(tooltipData.y) : yScale(Math.round((tooltipData.y / 12) * 100) / 100)}
                  r={3}
                  fill="black"
                />
              )}
            </svg>

            {/* Tooltip */}
            {tooltipData && tooltipPosition && (
              <div
                style={{
                  position: 'absolute',
                  top: tooltipPosition.y,
                  left: tooltipPosition.x,
                  backgroundColor: '#fff',
                  padding: '5px 10px',
                  borderRadius: '5px',
                  boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)',
                  justifyContent: 'center',
                  textAlign: 'center',
                  zIndex: 9999,
                  pointerEvents: 'none',
                  minWidth: '111px',
                }}>
                <h5 style={{margin: 0, padding: 0, color: theme.palette.customColors?.lightGrey || 'black'}}>
                  {tooltipTitle}
                </h5>
                <p style={{fontWeight: 'bold', fontSize: '14px', margin: 0, padding: 0}}>
                  {isUnit ? `${tooltipData.y.toFixed(2)} U/h` : `${(tooltipData.y / 12).toFixed(2)} U`}
                </p>
                <p
                  style={{
                    fontSize: '12px',
                    margin: 0,
                    padding: 0,
                  }}>
                  {moment(tooltipData.x).format('DD/MM/YY HH:mm')}
                </p>
              </div>
            )}
          </div>
        </Grid>
      </Grid>
    </div>
  );
};
