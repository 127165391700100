import {controlModeChanges, DynamicData} from '../../../api';
import React, {useRef, useEffect, useState, useMemo} from 'react';
import {scaleTime, scaleLinear} from '@visx/scale';
import {AxisBottom} from '@visx/axis';
import {Group} from '@visx/group';
import correctionBolusIcon from './assets/correctionBolus.svg';
import carbohydratesIcon from './assets/carbohydrates.svg';
import mealBolusIcon from './assets/mealBolus.svg';
import hypoTreatmentIcon from './assets/hypoTreatment.svg';
import {Box, Grid, IconButton, Tooltip, Typography, useTheme} from '@mui/material';
import GridColumns from '@visx/grid/lib/grids/GridColumns';
import GridRows from '@visx/grid/lib/grids/GridRows';
import moment from 'moment';
import {Icon} from '../../../components';
import {calculateInsuline, getEventPosition} from '../utils';

interface DataPoint {
  x: string;
  y: number;
  yAmount: number;
  isUnitU: boolean;
  icon: string;
  label: string;
  loopMode?: string;
  backgroundColor?: string;
}

interface BolusAndTreatmentChartProps {
  boluses: DynamicData['boluses'];
  microboluses: DynamicData['microboluses'];
  mealEvents: DynamicData['mealEvents'];
  controlModeChanges: DynamicData['controlModeChanges'];
  crosshairTime: number | null;
  selectedDateRange: string;
  setCrosshairTime: (time: number | null) => void;
  minX: number;
  maxX: number;
}

export const BolusAndTreatmentChart = ({
  boluses,
  microboluses,
  mealEvents,
  controlModeChanges,
  crosshairTime,
  setCrosshairTime,
  selectedDateRange,
  minX,
  maxX,
}: BolusAndTreatmentChartProps): JSX.Element => {
  const svgRef = useRef<SVGSVGElement>(null);
  const theme = useTheme();
  const [width, setWidth] = useState(1000);
  const [tooltip, setTooltip] = useState<{x: number; y: number; loopMode: string; label: string | undefined}>({
    x: 0,
    y: 0,
    loopMode: '#C1C1C1',
    label: '',
  });
  const [iconTooltip, setIconTooltip] = useState<{
    x: number;
    y: number;
    yAmount?: number;
    xFormatted?: string;
    label?: string;
    isUnitU: boolean;
  }>({
    x: 0,
    y: 0,
    yAmount: 0,
    xFormatted: '',
    label: '',
    isUnitU: false,
  });

  const height = 250;
  const margin = {top: 20, right: 20, bottom: 30, left: 40};
  const minY = 0;
  const maxY = 200;

  const filteredControlModeChanges = useMemo(() => {
    if (!controlModeChanges) return [];
    const seen: {[key: string]: boolean} = {};
    const result: typeof controlModeChanges = [];
    for (const change of controlModeChanges) {
      if (change.fromMode === change.toMode) continue;
      // TMP: filter out duplicates with different ID's
      const key =
        `${change.eventLocalDateTime}|${change.eventUTCInstance}|` +
        `${change.fromMode}|${change.isAutomaticChange}|` +
        `${change.loopMode}|${change.message}|` +
        `${change.participantId}|${change.toMode}`;

      if (!seen[key]) {
        seen[key] = true;
        result.push(change);
      }
    }
    return result;
  }, [controlModeChanges, minX, maxX]);

  const loopModeColors = {
    CLOSED_LOOP: {color: '#77ACA2', label: 'Closed-loop'},
    OPEN_LOOP: {color: '#EA9E8D', label: 'Open-loop'},
    undefined: {color: '#C1C1C1', label: 'Missing Values'},
  };

  const isLgDateRange =
    selectedDateRange === 'week' ||
    selectedDateRange === 'two-weeks' ||
    selectedDateRange === 'month' ||
    selectedDateRange === 'all';
  const iconsSize = isLgDateRange ? 25 : 50;
  // const labelsSize = isLgDateRange ? 8 : 11;

  // mealEvents
  const carbohydrates: DataPoint[] = mealEvents
    .filter(event => {
      const eventTime = new Date(event.eventLocalDateTime).getTime();
      return !event.isHypoTreatment && eventTime >= minX && eventTime <= maxX;
    })
    .map(event => ({
      x: event.eventLocalDateTime,
      y: 150,
      yAmount: event.carbohydratesEstimate,
      icon: carbohydratesIcon,
      label: 'Carbohydrates',
      isUnitU: false,
      loopMode: event.loopMode,
    }));
  const correctionBoluses: DataPoint[] = mealEvents
    .filter(event => {
      const eventTime = new Date(event.eventLocalDateTime).getTime();
      return event.isHypoTreatment && eventTime >= minX && eventTime <= maxX;
    })
    .map(event => ({
      x: event.eventLocalDateTime,
      y: 150,
      yAmount: event.carbohydratesEstimate,
      icon: hypoTreatmentIcon,
      label: 'Hypo treatment',
      isUnitU: false,
      loopMode: event.loopMode,
    }));

  // boluses
  const mealBolus: DataPoint[] = boluses
    .filter(event => {
      const eventTime = new Date(event.eventLocalDateTime).getTime();
      return (
        event.injectedValue != null &&
        event.mealBolusSafetyNetAmount != null &&
        eventTime >= minX &&
        eventTime <= maxX &&
        (event.deliveryStatus === 'DELIVERED' || event.deliveryStatus === 'PARTIALLY_DELIVERED')
      );
    })
    .map(bolus => ({
      x: bolus.eventLocalDateTime,
      y: 50,
      yAmount: bolus.injectedValue!,
      icon: mealBolusIcon,
      label: 'Meal bolus',
      isUnitU: true,
      backgroundColor: '#FFFFFF',
    }));
  const prandialBoluses: DataPoint[] = microboluses.reduce((pradial: DataPoint[], bolus) => {
    const eventTime = new Date(bolus.eventLocalDateTime).getTime();
    if (
      bolus.correctionBolusSafetyNetAmount != null &&
      bolus.injectedValue != null &&
      bolus.injectedValue != 0 &&
      bolus.deliveryStatus === 'DELIVERED' &&
      eventTime >= minX &&
      eventTime <= maxX
    ) {
      const {acb} = calculateInsuline(
        bolus.injectedValue,
        bolus.basalSafetyNetAmount,
        bolus.correctionBolusSafetyNetAmount,
        bolus.loopMode,
      );

      if (acb !== null && acb !== 0) {
        // add to accumulator only if acb valid
        pradial.push({
          x: bolus.eventLocalDateTime,
          y: 50,
          yAmount: acb,
          icon: correctionBolusIcon,
          label: 'Automatic\ncorrection bolus',
          isUnitU: true,
          backgroundColor: '#FFFFFF',
        });
      }
    }
    return pradial;
  }, []);

  const manualBoluses: DataPoint[] = boluses
    .filter(event => {
      const eventTime = new Date(event.eventLocalDateTime).getTime();
      return (
        event.injectedValue != null &&
        event.manualBolusSafetyNetAmount != null &&
        !event.isIOBAdjustment &&
        eventTime >= minX &&
        eventTime <= maxX &&
        (event.deliveryStatus === 'DELIVERED' || event.deliveryStatus === 'PARTIALLY_DELIVERED')
      );
    })
    .map(bolus => ({
      x: bolus.eventLocalDateTime,
      y: 50,
      yAmount: bolus.injectedValue!,
      icon: correctionBolusIcon,
      label: 'Manual\ncorrection bolus',
      isUnitU: true,
      backgroundColor: '#FFFFFF',
    }));

  const dataPoints: DataPoint[] = [
    ...carbohydrates,
    ...correctionBoluses,
    ...mealBolus,
    ...prandialBoluses,
    ...manualBoluses,
  ];

  useEffect(() => {
    if (svgRef.current) {
      setWidth(svgRef.current.clientWidth);
    }

    const handleResize = () => {
      if (svgRef.current) {
        setWidth(svgRef.current.clientWidth);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const xScale = scaleTime({
    domain: [minX, maxX],
    range: [margin.left, width - margin.right],
  });
  const yScale = scaleLinear({
    domain: [minY, maxY],
    range: [height - margin.bottom, margin.top],
  });

  // adjust tick number based on screen width
  const numTicks = useMemo(() => {
    return Math.floor(width / 75);
  }, [width]);

  const handlePointerMove = (
    event: React.PointerEvent<SVGElement> | React.MouseEvent<SVGElement> | React.TouchEvent<SVGImageElement>,
    iconData?: DataPoint,
    segments?: {startX: number; endX: number; color: string; label: string}[],
  ) => {
    const svgRect = svgRef.current?.getBoundingClientRect();
    if (!svgRect) return;

    const {x: mouseXPosition} = getEventPosition(event, svgRect);
    const timeAtMousePosition = xScale.invert(mouseXPosition).getTime();
    setCrosshairTime(timeAtMousePosition);

    const closestSegment =
      segments && segments.find(segment => mouseXPosition >= segment.startX && mouseXPosition <= segment.endX);

    if (closestSegment) {
      setTooltip({
        x: mouseXPosition,
        y: -5,
        loopMode: closestSegment.color,
        label: closestSegment.label,
      });
    }
    if (iconData) {
      const iconXPos = xScale(new Date(iconData.x)) - iconsSize / 2;
      const iconYPos = yScale(iconData.y) - iconsSize / 2;

      const tooltipXPos = Math.min(Math.max(iconXPos + iconsSize / 2, margin.left), width - margin.right - 115);
      const tooltipYPos = Math.min(Math.max(iconYPos - 60), height - margin.bottom - 1);

      setIconTooltip({
        x: tooltipXPos,
        y: tooltipYPos,
        xFormatted: moment(iconData.x).format('DD/MM/YY HH:mm'),
        label: iconData.label,
        yAmount: iconData.yAmount,
        isUnitU: iconData.isUnitU,
      });
      setCrosshairTime(null);
    }
  };

  const handlePointerLeave = () => {
    setCrosshairTime(null);
    setTooltip({x: 0, y: 0, loopMode: '', label: ''}); // loopMode tooltip
    setIconTooltip({x: 0, y: 0, xFormatted: '', label: '', yAmount: 0, isUnitU: false});
  };

  const renderContinuousLoopModeLine = (modeChanges: controlModeChanges[], xScale: any) => {
    // helper function grouping CGM data into color segments
    const getColorSegments = (modeChanges: controlModeChanges[]) => {
      const segments: {startX: number; endX: number; color: string; label: string}[] = [];
      if (modeChanges.length === 0) return segments; // return nothing in case there is no data

      // helper function to push the current segment
      const pushSegment = (startX: number, endX: number, color: string, label: string) => {
        segments.push({
          startX: xScale(startX),
          endX: xScale(endX),
          color,
          label,
        });
      };
      // get earliest and last mode change
      const last = modeChanges[0];
      const lastInfo = loopModeColors[last.loopMode as keyof typeof loopModeColors] || loopModeColors.OPEN_LOOP; // check if loop mode has changed
      const earliest = modeChanges[modeChanges.length - 1];
      const earliestInfo = loopModeColors[earliest.fromMode as keyof typeof loopModeColors] || loopModeColors.OPEN_LOOP; // check if loop mode has changed

      // if we only have one mode, create one segment from min to max
      if (modeChanges.length == 1) {
        if (minX > new Date(last.eventLocalDateTime).getTime()) {
          pushSegment(maxX, minX, lastInfo.color, lastInfo.label);
          return segments;
        }
      }

      // add segment from most recent time point(maxX) to most recent mode change
      pushSegment(maxX, new Date(last.eventLocalDateTime).getTime(), lastInfo.color, lastInfo.label);

      // loop through data to create segments fro each mode change
      if (modeChanges.length > 1) {
        for (let i = 1; i < modeChanges.length; i++) {
          const current = modeChanges[i];
          const before = modeChanges[i - 1];
          const currentInfo =
            loopModeColors[current.loopMode as keyof typeof loopModeColors] || loopModeColors.OPEN_LOOP;
          pushSegment(
            new Date(before.eventLocalDateTime).getTime(),
            new Date(current.eventLocalDateTime).getTime(),
            currentInfo.color,
            currentInfo.label,
          );
        }
      }
      // add segment from earliest time point(minX) to earliest mode change
      pushSegment(new Date(earliest.eventLocalDateTime).getTime(), minX, earliestInfo.color, earliestInfo.label);
      return segments;
    };
    const segments = getColorSegments(modeChanges); // get all segments based on loop mode color

    return (
      <g>
        {segments.map((segment, index) => {
          return (
            <line
              key={`loopmode-line-${index}`}
              x1={segment.startX}
              y1={10}
              x2={segment.endX}
              y2={10}
              stroke={segment.color}
              strokeWidth={20}
              onContextMenu={event => event.preventDefault()}
              onPointerEnter={event => {
                const svgRect = svgRef.current?.getBoundingClientRect();
                if (!svgRect) return;
                setTooltip({
                  x: event.clientX - svgRect.left,
                  y: -5,
                  loopMode: segment.color,
                  label: segment.label,
                });
              }}
              onPointerMove={event => handlePointerMove(event, undefined, segments)}
              onPointerLeave={handlePointerLeave}
            />
          );
        })}
      </g>
    );
  };

  const renderIconsAndLabels = (data: DataPoint[], xZoomScale: any, yZoomScale: any) =>
    data.map((d, i) => {
      // calculate icon position
      const iconXPos = xZoomScale(new Date(d.x)) - iconsSize / 2;
      const iconYPos = yZoomScale(d.y) - iconsSize / 2;
      return (
        <g key={`item-${i}`}>
          <image
            cursor={'pointer'}
            transform={'translateZ(0)'}
            href={d.icon}
            x={iconXPos}
            y={iconYPos}
            width={iconsSize}
            height={iconsSize}
            onContextMenu={event => event.preventDefault()}
            onTouchStart={(e: React.TouchEvent<SVGImageElement>) => {
              e.preventDefault();
              e.stopPropagation();
              handlePointerMove(e, d);
            }}
            onPointerDown={(e: React.PointerEvent<SVGImageElement>) => {
              if (e.pointerType === 'touch') {
                e.preventDefault();
                handlePointerMove(e, d);
              }
            }}
            onTouchEnd={e => e.preventDefault()}
            onMouseDown={event => event.preventDefault()}
            onMouseEnter={() => {
              const svgRect = svgRef.current?.getBoundingClientRect();
              if (!svgRect) return;
              // force crosshair to x value
              setCrosshairTime(Number(new Date(d.x)));

              // define tooltip position boundaries
              const tooltipXPos = Math.min(Math.max(iconXPos + iconsSize / 2, margin.left), width - margin.right - 115);
              const tooltipYPos = Math.min(Math.max(iconYPos - 60), height - margin.bottom - 1);

              setIconTooltip({
                x: tooltipXPos,
                y: tooltipYPos,
                xFormatted: moment(d.x).format('DD/MM/YY HH:mm'),
                label: d.label,
                yAmount: d.yAmount,
                isUnitU: d.isUnitU,
              });
            }}
            onPointerLeave={handlePointerLeave}
            style={{
              touchAction: 'manipulation',
              pointerEvents: 'all',
              WebkitTouchCallout: 'none',
              userSelect: 'none',
              WebkitUserSelect: 'none',
            }}
          />
          {/* <text
            x={xZoomScale(new Date(d.x))}
            y={yZoomScale(d.y) + iconsSize / 2 + 10}
            textAnchor="middle"
            fontSize={labelsSize}
            fill="black"
            pointerEvents="none">
            {d.isUnitU ? d.yAmount.toFixed(2) + ' U' : d.yAmount.toFixed(0) + ' g'}
          </text> */}
        </g>
      );
    });

  const crosshairXPosition = crosshairTime ? xScale(new Date(crosshairTime)) : null;

  return (
    <Grid item container xs={12} mt={2} marginTop={'50px'}>
      <Grid item xs={1} lg={0.5} position={'relative'} display={'flex'} justifyContent="center" marginLeft="auto">
        <Box sx={{cursor: 'default', position: 'relative'}}>
          <Tooltip
            title={
              <React.Fragment>
                <Typography variant="h6" gutterBottom>
                  Operation mode and events
                </Typography>
                <Typography variant="body2">
                  The operation mode ribbon is pink in open-loop and green in closed-loop. Relevant events are displayed
                  as icons over time: a pizza icon for carbohydrate intake, a black syringe icon for meal insulin
                  boluses, a red syringe icon for manual or automatic insulin correction boluses, and a black candy icon
                  for hypoglycemia treatments. Details of the events are displayed when hovering over them.
                </Typography>
              </React.Fragment>
            }
            arrow>
            <div style={{pointerEvents: 'auto', cursor: 'inherit'}}>
              <IconButton>
                <Icon icon="info" size={16} color={theme.palette.basic[500]} />
              </IconButton>
            </div>
          </Tooltip>
        </Box>
      </Grid>
      <Grid item xs={11} lg={11.5}>
        <Typography sx={{color: '#2B2C2E', fontSize: '20px', fontWeight: '600', marginRight: '16px'}}></Typography>
        <svg ref={svgRef} width="100%" height={height} overflow={'visible'}>
          <Group>
            {/* LoopMode*/}
            {renderContinuousLoopModeLine(filteredControlModeChanges, xScale)}
            {/* LoopMode Tooltip */}
            {tooltip.loopMode && (
              <text
                x={tooltip.x}
                y={tooltip.y}
                fontSize={12}
                fontWeight="bold"
                fill="black"
                textAnchor="middle"
                pointerEvents="none">
                {tooltip.label}
              </text>
            )}
            {/* Grid */}
            <GridColumns
              scale={xScale}
              top={margin.top}
              height={height - margin.bottom - margin.top}
              stroke="#e0e0e0"
              strokeDasharray="5,5"
              pointerEvents="none"
            />
            <GridRows
              scale={yScale}
              width={width - margin.right - margin.left}
              strokeDasharray="2,2"
              left={margin.left}
            />
            <AxisBottom
              top={height - margin.bottom}
              scale={xScale}
              numTicks={numTicks}
              tickClassName="axis-tick"
              tickFormat={d => {
                const date = d instanceof Date ? d : new Date(d.valueOf());
                return date.toLocaleTimeString('en-EU', {
                  hour: '2-digit',
                  minute: '2-digit',
                  hour12: false,
                });
              }}
              tickLabelProps={() => ({
                fill: 'black',
                fontSize: 11,
                textAnchor: 'middle',
              })}
            />
            <rect
              x={margin.left}
              y={margin.top}
              width={width - margin.left - margin.right}
              height={height - margin.top - margin.bottom}
              fill={'transparent'}
              onPointerMove={handlePointerMove}
              onPointerLeave={handlePointerLeave}
              pointerEvents="all"
            />
            {/* Crosshair */}
            {crosshairXPosition !== null && (
              <line
                x1={crosshairXPosition}
                x2={crosshairXPosition}
                y1={margin.top}
                y2={height - margin.bottom}
                stroke="gray"
                strokeDasharray="4,4"
              />
            )}
            {/* Icons and Labels */}
            {renderIconsAndLabels(dataPoints, xScale, yScale)}
            {iconTooltip.xFormatted && iconTooltip.label && (
              <foreignObject x={iconTooltip.x} y={iconTooltip.y} width={200} height={180} pointerEvents="none">
                <div
                  style={{
                    position: 'absolute',
                    backgroundColor: '#fff',
                    padding: '5px 10px',
                    borderRadius: '5px',
                    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)',
                    justifyContent: 'center',
                    textAlign: 'center',
                    zIndex: 9999,
                    minWidth: '111px',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    maxWidth: '130px',
                  }}>
                  <h5
                    style={{
                      margin: 0,
                      padding: 0,
                      whiteSpace: 'pre-line',
                      color: theme.palette.customColors?.lightGrey || 'black',
                    }}>
                    {iconTooltip.label}
                  </h5>
                  <p style={{fontWeight: 'bold', fontSize: '14px', margin: 0, padding: 0}}>
                    {(() => {
                      if (iconTooltip.yAmount === undefined) {
                        return 'N/A';
                      }

                      if (iconTooltip.isUnitU) {
                        return `${iconTooltip.yAmount.toFixed(2)} U`;
                      }

                      return `${iconTooltip.yAmount.toFixed(0)} g`;
                    })()}
                  </p>
                  <p style={{fontSize: '12px', margin: 0, padding: 0}}>{iconTooltip.xFormatted}</p>
                </div>
              </foreignObject>
            )}
          </Group>
        </svg>
      </Grid>
    </Grid>
  );
};
