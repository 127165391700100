import {EventResponse, userService, wsService} from '../../../api';
import {NoData} from '../../../components/data-tables/utils';
import {ENDPOINT_TIME_OPTIONS} from '../../../constants';
import {StyledTablesContainer} from '../../../pages/events/styles';
import {TableHead} from '../../../components/table-head';
import {useEffect, useState} from 'react';
import {AssignUser, DialogBox} from '../../../components';
import CircleIcon from '@mui/icons-material/Circle';
import {StyledBodyTableRow, StyledBodyTableCell, StyledCgmContainer, StyledInputLabel} from './styles';
import {useAppDispatch} from '../../../store';
import CommentIcon from '@mui/icons-material/Comment';
import {setEventDetailsDialogOpen} from '../../../store/reducers/commentsActionSlice';
import {changeFormateDate} from '../../../utils';
import {
  Box,
  FormControl,
  Select,
  MenuItem,
  TablePagination,
  SelectChangeEvent,
  Table,
  TableBody,
  Typography,
  useTheme,
  TableContainer,
  Grid,
  Tooltip,
} from '@mui/material';
import {StatusComponent} from '../../../components/status/Status';
import {EventsTableFiltersProps} from './types';
import {cells, priorityMap, eventStatus, eventTypesMap} from './utils';
import {useEvents} from '../../../hooks/useEvents';

export const EventsTableFilter = ({participants}: EventsTableFiltersProps) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const {data: users} = userService.useGetUsersQuery();

  const [currentStart, setCurrentStart] = useState(0);
  const [limit, setLimit] = useState(10);
  const [nextStart, setNextStart] = useState(limit);
  const [timeFrame, setTimeFrame] = useState<string>(ENDPOINT_TIME_OPTIONS.THREE_HOURS);
  const [selectedParticipant, setSelectedParticipant] = useState<string>('-');
  const [selectedStatus, setSelectedStatus] = useState<string>('-');
  const [assignedUserId, setAssignedUserId] = useState<string>('-');
  const [page, setPage] = useState(0);
  const [openEventDetailsDialog, setOpenEventDetailsDialog] = useState<boolean>(false);
  const [selectedEvent, setSelectedEvent] = useState<any>();
  const [eventList, setEventList] = useState<EventResponse[]>([]);
  wsService.connect();

  const buildQueryParams = () => {
    const params = new URLSearchParams();
    params.append('start', String(currentStart));
    params.append('limit', String(limit));
    params.append('timeframe', timeFrame);
    if (selectedParticipant !== '-') params.append('participant_id', selectedParticipant);
    if (selectedStatus !== '-') params.append('status', selectedStatus);
    if (assignedUserId !== '-') params.append('assigned_user_id', assignedUserId);

    return params.toString();
  };

  const {data: events} = useEvents({
    buildQueryParams,
    dependencies: [selectedEvent, selectedParticipant, selectedStatus, timeFrame, limit, currentStart, assignedUserId],
  });

  useEffect(() => {
    if (events?.data) {
      setEventList(events.data);
    }
  }, [events]);

  useEffect(() => {
    wsService.listener((newEvent: EventResponse) => {
      setEventList(prevEvents => [newEvent, ...prevEvents]);
    });
  }, []);

  // Handle Page change
  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    if (newPage < page) {
      setNextStart(currentStart);
      setCurrentStart(currentStart - limit);
      setPage(newPage);
    } else {
      setCurrentStart(nextStart);
      setNextStart(nextStart + limit);
      setPage(newPage);
    }
  };
  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setLimit(parseInt(event.target.value));
    setNextStart(parseInt(event.target.value));
    setCurrentStart(0);
    setPage(0);
  };

  // Handle Event Dialog
  const handleEventDialogOpen = () => {
    setOpenEventDetailsDialog(true);
    dispatch(setEventDetailsDialogOpen(true));
  };
  const handleEventDialogClose = () => {
    setOpenEventDetailsDialog(false);
    dispatch(setEventDetailsDialogOpen(false));
  };
  const handleEventDialogRefetch = () => {
    dispatch(setEventDetailsDialogOpen(false));
  };

  // Handle Filters change
  const handleChangeTimeFrame = (event: SelectChangeEvent) => setTimeFrame(event.target.value);
  const handleChangeParticipants = (event: SelectChangeEvent) => setSelectedParticipant(event.target.value);
  const handleChangeEventStatus = (event: SelectChangeEvent) => setSelectedStatus(event.target.value);
  const handleChangeAssignedUserId = (event: SelectChangeEvent) => setAssignedUserId(event.target.value);

  const isDataAvailable = events && events.totalCount != 0;

  return (
    <StyledTablesContainer>
      <Box>
        <Grid container item xs={12} spacing={2} mt={2} mb={3}>
          <Grid item xs={12} md={4}>
            <FormControl fullWidth>
              <StyledInputLabel id="demo-simple-select-label">Time frame</StyledInputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={timeFrame}
                label="Time frame"
                onChange={handleChangeTimeFrame}>
                <MenuItem value={ENDPOINT_TIME_OPTIONS.HOUR}>1 Hour</MenuItem>
                <MenuItem value={ENDPOINT_TIME_OPTIONS.THREE_HOURS}>3 Hours</MenuItem>
                <MenuItem value={ENDPOINT_TIME_OPTIONS.SIX_HOURS}>6 Hours</MenuItem>
                <MenuItem value={ENDPOINT_TIME_OPTIONS.EIGHT_HOURS}>8 Hours</MenuItem>
                <MenuItem value={ENDPOINT_TIME_OPTIONS.TEN_HOURS}>10 Hours</MenuItem>
                <MenuItem value={ENDPOINT_TIME_OPTIONS.TWELVE_HOURS}>12 Hours</MenuItem>
                <MenuItem value={ENDPOINT_TIME_OPTIONS.SIXTEEN_HOURS}>16 Hours</MenuItem>
                <MenuItem value={ENDPOINT_TIME_OPTIONS.DAY}>24 Hours</MenuItem>
                <MenuItem value={ENDPOINT_TIME_OPTIONS.THREE_DAYS}>3 Days</MenuItem>
                <MenuItem value={ENDPOINT_TIME_OPTIONS.WEEK}>1 Week</MenuItem>
                <MenuItem value={ENDPOINT_TIME_OPTIONS.TWO_WEEKS}>2 Weeks</MenuItem>
                <MenuItem value={ENDPOINT_TIME_OPTIONS.MONTH}>1 Month</MenuItem>
                <MenuItem value={ENDPOINT_TIME_OPTIONS.ALL_TIME}>All time</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={4}>
            <FormControl fullWidth>
              <StyledInputLabel id="participants-select-label">Participant</StyledInputLabel>
              <Select
                labelId="participants-select-label"
                id="participants-simple-select"
                value={selectedParticipant}
                label="Participant"
                onChange={handleChangeParticipants}>
                <MenuItem value="-">-</MenuItem>
                {Object.keys(participants).map((participant, i) => (
                  <MenuItem key={i} value={participants[participant as any].id}>
                    {participants[participant as any].id} - {participants[participant as any].name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={4}>
            <FormControl fullWidth>
              <StyledInputLabel id="eventStatus-select-label">Status</StyledInputLabel>
              <Select
                labelId="eventStatus-select-label"
                id="eventStatus-simple-select"
                value={selectedStatus}
                label="Status"
                onChange={handleChangeEventStatus}>
                <MenuItem value="-">-</MenuItem>
                <MenuItem value={'new'}>New</MenuItem>
                <MenuItem value={'in-progress'}>In progress</MenuItem>
                <MenuItem value={'completed'}>Completed</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={4}>
            <FormControl fullWidth>
              <StyledInputLabel id="assigned-user-id-select">Assigned user</StyledInputLabel>
              <Select
                labelId="assigned-user-id-label"
                id="assigned-user-id-select"
                value={assignedUserId}
                label="Assigned user"
                onChange={handleChangeAssignedUserId}>
                <MenuItem value="-">-</MenuItem>
                {users &&
                  users.map((user, i) => (
                    <MenuItem key={i} value={user.id}>
                      {user.firstName} {user.lastName}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Box>

      {isDataAvailable ? (
        <TableContainer>
          <Table>
            <TableHead cells={cells} />
            <TableBody>
              {eventList?.map((event: EventResponse) => {
                const eventID = event.id;
                const eventType = event.eventNotificationType ? eventTypesMap[event.eventNotificationType] : null;
                const eventPriority = event.priority;
                const participantId = event.participantId;
                const noOfComments = event.commentCount;
                const eventLocalDateTime = event.originatingEvent ? event.originatingEvent.eventLocalDateTime : 'Null';
                const eventSystemTime = event.originatingEvent
                  ? new Date(event.originatingEvent.eventUTCInstant)
                  : new Date(event.notificationUTCInstant);
                return (
                  <StyledBodyTableRow key={eventID} hover>
                    <StyledBodyTableCell align="center">
                      <Typography variant="bodyBigSemibold" color={theme.palette.basic[1000]}>
                        {eventID}
                      </Typography>
                    </StyledBodyTableCell>
                    <StyledBodyTableCell>
                      <Box sx={{display: 'flex', flexDirection: 'column'}}>
                        <Typography
                          variant="captionBig"
                          color={theme.palette.primaryCustom[1000]}
                          sx={{'&:hover': {cursor: 'pointer', color: theme.palette.primaryCustom[800]}}}
                          onClick={() => {
                            handleEventDialogOpen();
                            setSelectedEvent(event);
                          }}>
                          {eventType}
                        </Typography>
                      </Box>
                    </StyledBodyTableCell>
                    <StyledBodyTableCell align="center">{participantId}</StyledBodyTableCell>
                    <StyledBodyTableCell align="center">
                      <Tooltip title={priorityMap[eventPriority]?.label} arrow>
                        <CircleIcon
                          sx={{
                            height: '8px',
                            width: '8px',
                            mt: '5px',
                            mr: '5px',
                            color: priorityMap[eventPriority]?.color,
                          }}
                        />
                      </Tooltip>
                    </StyledBodyTableCell>
                    <StyledBodyTableCell align="center">
                      <StatusComponent
                        onClose={handleEventDialogClose}
                        selectedEvent={event}
                        uniqueStatuses={eventStatus}
                        onRefetch={handleEventDialogRefetch}
                        setSelectedEvent={setSelectedEvent}
                      />
                    </StyledBodyTableCell>
                    <StyledBodyTableCell align="center">
                      <StyledCgmContainer>
                        <AssignUser
                          event={event}
                          onRefetch={() => {
                            handleEventDialogRefetch();
                          }}
                          onClose={handleEventDialogClose}
                          setSelectedEvent={setSelectedEvent}
                        />
                      </StyledCgmContainer>
                    </StyledBodyTableCell>
                    <StyledBodyTableCell align="center">
                      <Typography variant="bodySmallBold" color={theme.palette.basic[800]}>
                        <CommentIcon
                          sx={{
                            height: '16px',
                            width: '16px',
                            color: theme.palette.basic[300],
                            mr: '6px',
                            marginBottom: '-4px',
                          }}
                        />
                        {noOfComments}
                      </Typography>
                    </StyledBodyTableCell>
                    <StyledBodyTableCell>
                      <Typography variant="bodyBig" color={theme.palette.basic[1000]}>
                        {eventSystemTime ? changeFormateDate(eventSystemTime.toString()) : 'No event date available!'}
                      </Typography>
                    </StyledBodyTableCell>
                    <StyledBodyTableCell>
                      <Typography variant="bodyBig" color={theme.palette.basic[1000]}>
                        {eventLocalDateTime ? changeFormateDate(eventLocalDateTime) : 'No event date available!'}
                      </Typography>
                    </StyledBodyTableCell>
                  </StyledBodyTableRow>
                );
              })}
              {selectedEvent && (
                <DialogBox
                  selectedEvent={selectedEvent}
                  uniqueStatuses={eventStatus}
                  dialogOpen={openEventDetailsDialog}
                  onClose={handleEventDialogClose}
                  onRefetch={handleEventDialogRefetch}
                  setSelectedEvent={setSelectedEvent}
                />
              )}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <NoData />
      )}
      <TablePagination
        component="div"
        count={events?.totalCount ? events?.totalCount : -1}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={limit}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </StyledTablesContainer>
  );
};
