import moment from 'moment';
import {timeZone} from '../../utils';
import {controlModeChanges} from '../../../src/api';

export const timeSampConvert = (timeSamp: string) => {
  return moment.tz(timeSamp, timeZone).format('YYYY-MM-DDTHH:mm:ss');
};

// get micro bolus (MB) and automatic correction bolus (ACB) from total total injected insulin (TD)
export const calculateInsuline = (
  injectedValue: number | null,
  basalSafetyNetAmount: number | null,
  correctionBolusSafetyNetAmount: number | null,
  loopMode: string,
): {
  mb: number | null;
  acb: number | null;
} => {
  const validInjectedValue = injectedValue ?? 0;
  let validBasalSafetyNetAmount;
  let validCorrectionBolusSafetyNetAmount;

  if (validInjectedValue === 0) {
    validBasalSafetyNetAmount = 0;
    validCorrectionBolusSafetyNetAmount = 0;
  } else {
    validBasalSafetyNetAmount = basalSafetyNetAmount ?? 0;
    validCorrectionBolusSafetyNetAmount = correctionBolusSafetyNetAmount ?? 0;
  }

  // case 1: TD = 0, MB = 0, ACB = 0
  if (
    loopMode === 'CLOSED_LOOP' &&
    validInjectedValue === 0 &&
    validBasalSafetyNetAmount === 0 &&
    validCorrectionBolusSafetyNetAmount === 0
  ) {
    return {mb: 0, acb: 0};
  }

  // case 2: TD > 0
  if (validInjectedValue > 0 && loopMode == 'CLOSED_LOOP') {
    // MB = 0, ACB = 0, TD > 0 -> ACB = TD
    if (validBasalSafetyNetAmount === 0 && validCorrectionBolusSafetyNetAmount === 0 && validInjectedValue > 0) {
      return {mb: 0, acb: injectedValue};
    }
    // MB = 0, ACB > 0 -> ACB = TD
    if (validBasalSafetyNetAmount === 0 && validCorrectionBolusSafetyNetAmount > 0) {
      return {mb: 0, acb: injectedValue};
    }
    // MB > 0, ACB = 0 -> MB = TD
    if (validBasalSafetyNetAmount > 0 && validCorrectionBolusSafetyNetAmount === 0) {
      return {mb: injectedValue, acb: 0};
    }
    // MB > 0, ACB > 0 -> MB = TD - ACB_safetyNetAmount, ACB = ACB_safetyNetAmount
    if (validBasalSafetyNetAmount > 0 && validCorrectionBolusSafetyNetAmount > 0) {
      return {
        mb: validInjectedValue - validCorrectionBolusSafetyNetAmount,
        acb: validCorrectionBolusSafetyNetAmount,
      };
    }
  }

  // null in case no valid calculation possible
  return {mb: null, acb: null};
};

function isTouchEvent(event: unknown): event is React.TouchEvent<SVGElement> {
  return !!event && typeof event === 'object' && 'touches' in event;
}
function isPointerEvent(event: unknown): event is React.PointerEvent<SVGElement> | React.MouseEvent<SVGElement> {
  return !!event && typeof event === 'object' && 'clientX' in event && 'clientY' in event;
}

export const getEventPosition = (event: React.SyntheticEvent, svgRect: DOMRect): {x: number; y: number} => {
  // handle touch events
  if (isTouchEvent(event) && event.touches.length > 0) {
    return {
      x: event.touches[0].clientX - svgRect.left,
      y: event.touches[0].clientY - svgRect.top,
    };
  }

  // handle pointer/mouse events
  if (isPointerEvent(event)) {
    return {
      x: event.clientX - svgRect.left,
      y: event.clientY - svgRect.top,
    };
  }
  return {x: 0, y: 0};
};

interface DataPoint {
  x: Date;
  y: number;
}

export const getNearestModeChangeDiff = (
  modeChanges: controlModeChanges[],
  lastPointTime: number,
): {hasModeChanged: boolean; modeChangeDiff: number} => {
  if (!modeChanges || modeChanges.length === 0) {
    return {hasModeChanged: false, modeChangeDiff: Infinity};
  }
  const threshold = 5.5 * 60 * 1000;
  // iterate over the modeChanges array
  for (const change of modeChanges) {
    const changeTime = new Date(change.eventLocalDateTime).getTime();
    // if there is a change of mode in the next 10 minutes after or 5 minutes before valid micro bolus
    if (lastPointTime < changeTime && changeTime - lastPointTime <= threshold * 2) {
      return {hasModeChanged: true, modeChangeDiff: changeTime - lastPointTime};
    } else if (
      lastPointTime > changeTime &&
      lastPointTime - threshold < changeTime &&
      lastPointTime - changeTime <= threshold
    ) {
      return {hasModeChanged: true, modeChangeDiff: 0};
    }
  }
  return {hasModeChanged: false, modeChangeDiff: Infinity};
};
// get basal profile value at a specific time point
export const getBasalValueAtTime = (time: number, basalData: number[]): number => {
  const hour = new Date(time).getHours();
  // return closest basal profile value or 0
  return basalData[hour] ?? 0;
};

// helper function to split data into segments if data is missing
export const splitDataIntoSegments = (
  data: DataPoint[],
  modeChanges: controlModeChanges[],
  basalData: number[],
  maxX: number,
  threshold: number = 5.5 * 60 * 1000,
): DataPoint[][] => {
  const segments: DataPoint[][] = [];
  let currentSegment: DataPoint[] = [];
  const reversedData = [...data].reverse();
  const reversedMode = [...modeChanges].reverse();
  if (data.length < 1) {
    return [];
  }
  // iterate through microbolus data to create segments between OL mode changes
  for (let i = 0; i < reversedData.length; i++) {
    const point = reversedData[i];
    const pointTime = point.x.getTime();
    const prevPoint = currentSegment.length > 0 ? currentSegment[currentSegment.length - 1].x : null;
    const prevPointTime = prevPoint ? prevPoint.getTime() : Infinity;

    // get time difference between last microbolus and current one
    const timeDifference = currentSegment.length > 0 ? pointTime - prevPointTime : Infinity;

    // if currentSegment is empty/ gap between point and it's predecessor is within the threshold
    if (currentSegment.length === 0 || timeDifference <= threshold) {
      // get last point from current segment
      currentSegment.push(point);
      continue;
    }
    // get valid OL mode changes near time point (15 minutes after time point)
    const {hasModeChanged, modeChangeDiff} = getNearestModeChangeDiff(reversedMode, prevPointTime + threshold);
    const modeChangeTime = prevPointTime + threshold + modeChangeDiff;
    const basalValue = getBasalValueAtTime(modeChangeTime, basalData);

    // if there is a gap after last time point (>5min) and no manual OL switch happened
    if (modeChangeDiff != 0) {
      currentSegment.push({
        x: new Date(prevPointTime + threshold),
        y: 0,
      });
    }

    // if there is a gap after last time point (<=10min) and a manual OL switch happened
    if (hasModeChanged && modeChangeDiff <= threshold) {
      currentSegment.push({
        x: new Date(modeChangeTime),
        y: basalValue,
      });
      segments.push(currentSegment);
      currentSegment = [point];
      continue;
    }
    // if there is a gap after last time point (<=10min) and no manual OL switch happened
    if (timeDifference <= threshold * 2) {
      currentSegment.push(point);
      continue;
    }
    // if there is a gap after last time point (>10min) and no manual OL switch happened
    if (timeDifference > threshold * 2) {
      currentSegment.push({
        x: new Date(prevPointTime + threshold * 2),
        y: 0,
      });
    }
    // if there is a gap after last time point (10min<x<=15min) and a manual OL switch happened
    if (hasModeChanged && modeChangeDiff <= threshold * 2 && modeChangeDiff > threshold) {
      currentSegment.push({
        x: new Date(modeChangeTime),
        y: basalValue,
      });
      segments.push(currentSegment);
      currentSegment = [point];
      continue;
    }
    // if there is a gap after last time point (<=15min) and no manual OL switch happened
    if (timeDifference <= threshold * 3) {
      currentSegment.push(point);
      continue;
    } else {
      // if time gap too large (>15min), push current segment and start a new one
      segments.push(currentSegment);
      currentSegment = [point];
    }
  }
  // handle last segment
  if (currentSegment.length > 0) {
    const lastPoint = currentSegment[currentSegment.length - 1];
    const lastPointTime = lastPoint.x.getTime();
    const {hasModeChanged, modeChangeDiff} = getNearestModeChangeDiff(reversedMode, lastPointTime + threshold);
    const modeChangeTime = lastPointTime + threshold + modeChangeDiff;
    const basalValue = getBasalValueAtTime(modeChangeTime, basalData);

    if (hasModeChanged) {
      // Mode change within 5.5-11min (threshold*2)
      if (modeChangeDiff <= threshold * 2) {
        if (modeChangeTime <= maxX) {
          currentSegment.push({
            x: new Date(modeChangeTime),
            y: basalValue,
          });
          // If mode change was between 5.5-11min, we might need second extension
          if (modeChangeDiff > threshold && modeChangeTime + threshold <= maxX) {
            currentSegment.push({
              x: new Date(modeChangeTime + threshold),
              y: 0,
            });
          }
        }
      }
    } else {
      // Always add first 5.5min extension if possible
      if (lastPointTime + threshold <= maxX) {
        currentSegment.push({
          x: new Date(lastPointTime + threshold),
          y: 0,
        });
        // Add second 5.5min extension (total 11min) if possible
        if (lastPointTime + threshold * 2 <= maxX) {
          currentSegment.push({
            x: new Date(lastPointTime + threshold * 2),
            y: 0,
          });
          // Add third 5.5min extension (total 16.5min) if possible
          // (Matches your threshold*3 check in main loop)
          if (lastPointTime + threshold * 3 <= maxX) {
            currentSegment.push({
              x: new Date(lastPointTime + threshold * 3),
              y: 0,
            });
          }
        }
      }
    }
    // Final safety check - ensure we never exceed maxX
    while (currentSegment.length > 0 && currentSegment[currentSegment.length - 1].x.getTime() > maxX) {
      currentSegment.pop();
    }

    // Add final point exactly at maxX if we're close but not quite there
    if (currentSegment.length > 0) {
      const finalPoint = currentSegment[currentSegment.length - 1];
      const finalTime = finalPoint.x.getTime();
      if (finalTime < maxX && maxX - finalTime < threshold * 2) {
        currentSegment.push({
          x: new Date(maxX),
          y: finalPoint.y,
        });
      }
    }
    segments.push(currentSegment);
  }
  return segments;
};

// helper function to extend segment for another five minute
const extendLastDataPoint = (
  segments: DataPoint[][],
  maxX: number,
  threshold: number = 5.5 * 60 * 1000,
): DataPoint[][] => {
  return segments.map(segment => {
    if (segment.length > 0) {
      // extend last point of the segment by 5 minutes
      const lastPoint = segment[segment.length - 1];
      const lastPointTime = lastPoint.x.getTime() + threshold;
      if (lastPointTime <= maxX) {
        return [...segment, {...lastPoint, x: new Date(lastPointTime)}];
      }
      if (lastPointTime > maxX) {
        return [...segment, {...lastPoint, x: new Date(maxX)}];
      }
    }
    return segment;
  });
};

// get the corresponding basal value for time points in microbolus segment
export const getBasalValueForMicrobolusData = (microbolusFilteredData: DataPoint[][], basalData: number[]) => {
  return microbolusFilteredData.map(segment => {
    return segment.map(dataPoint => {
      // for each microbolus data in the segment, get the corresponding basal value
      const basalValue = getBasalValueAtTime(dataPoint.x.getTime(), basalData);
      // return object with same time point (x) and corresponding basal profile value (y)
      return {x: dataPoint.x, y: basalValue};
    });
  });
};

// helper function to add basal data points at specified times
const addBasalDataPoints = (
  currentTime: number,
  endTime: number,
  basalData: number[],
  threshold: number = 5 * 60 * 1000,
  finalData: DataPoint[],
) => {
  while (currentTime <= endTime) {
    const basalValue = getBasalValueAtTime(currentTime, basalData);
    finalData.push({x: new Date(currentTime), y: basalValue});
    currentTime += threshold;
  }
  return finalData;
};

export const combineMicrobolusAndBasalData = (
  microbolusesDataProcessed: DataPoint[][],
  basalData: number[],
  minX: number,
  maxX: number,
  threshold = 5.5 * 60 * 1000,
): DataPoint[] => {
  let finalData: DataPoint[] = [];

  // if no microbolus data exists, fill entire range with basal data
  if (microbolusesDataProcessed.length === 0) {
    addBasalDataPoints(minX, maxX, basalData, threshold, finalData);
    finalData = extendLastDataPoint([finalData], maxX)[0];
    return finalData;
  }

  // fill gaps before first microbolus segment
  const firstSegmentStartTime = microbolusesDataProcessed[0][0].x.getTime();
  if (firstSegmentStartTime > minX && firstSegmentStartTime - minX >= threshold) {
    finalData = addBasalDataPoints(minX, firstSegmentStartTime, basalData, threshold, finalData);
  }

  // process each microbolus segment
  microbolusesDataProcessed.forEach((segment, index) => {
    // add microbolus points from current segment
    finalData.push(...segment);
    // fill gaps between segments with basal points
    const nextSegment = microbolusesDataProcessed[index + 1];
    if (nextSegment) {
      const lastPointTime = segment[segment.length - 1].x.getTime();
      const nextSegmentFirstTime = nextSegment[0].x.getTime();

      if (nextSegmentFirstTime - lastPointTime >= threshold) {
        finalData = addBasalDataPoints(lastPointTime, nextSegmentFirstTime, basalData, threshold, finalData);
      }
    }
  });

  // fill gaps after last microbolus segment
  const lastSegmentEndTime = microbolusesDataProcessed.slice(-1)[0].slice(-1)[0].x.getTime();
  if (maxX - lastSegmentEndTime >= threshold) {
    finalData = addBasalDataPoints(lastSegmentEndTime, maxX, basalData, threshold, finalData);
  }

  return finalData;
};
