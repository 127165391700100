import {ENDPOINT_TIME_OPTIONS} from '../../../constants';
import {useCallback, useEffect, useState} from 'react';
import {Box, FormControl, MenuItem, Select, SelectChangeEvent, CircularProgress} from '@mui/material';
import {BloodGlucoseChart, InsulinAndMealsChart} from '../../../components';
import {BasalProfile, DynamicData} from '../../../api';
import {useParams} from 'react-router-dom';
import {BolusAndTreatmentChart} from '../../../components/charts/bolus-and-treatment/BolusAndTreatment';
import {getTimeDomain} from './utils';
import {useRefetchData} from '../../../hooks/useRefetchData';
import {StyledInputLabel} from './styles';

export const GraphsWrapper = ({
  basalProfiles,
  dynamicData,
  onSelectedDateRangeChange,
  TDI,
}: {
  basalProfiles: BasalProfile[];
  dynamicData: DynamicData;
  onSelectedDateRangeChange: (selectedDateRange: string) => void;
  TDI: number | null;
}) => {
  const {id} = useParams();
  const [crosshairTime, setCrosshairTime] = useState<number | null>(null);
  const [selectedDateRange, setSelectedDateRange] = useState<string>(
    sessionStorage.getItem('sessionTimeFrame') || ENDPOINT_TIME_OPTIONS.THREE_HOURS,
  );
  const [param, setParam] = useState<string>(`${id}?timeframe=${selectedDateRange}`);
  const initialTimeDomain = getTimeDomain(selectedDateRange, new Date().toISOString());
  const [timeDomain, setTimeDomain] = useState<{minX: number; maxX: number}>({
    minX: initialTimeDomain[0],
    maxX: initialTimeDomain[1],
  });
  const [graphData, setGraphData] = useState<DynamicData>(dynamicData);
  const {latestDynamicData, isLoadingDynamicData, refetchDynamicData} = useRefetchData(param, 0);

  useEffect(() => {
    refetchDynamicData();
  }, [param, refetchDynamicData]);

  useEffect(() => {
    const data = latestDynamicData || dynamicData;
    if (data) {
      const [newMinX, newMaxX] = getTimeDomain(selectedDateRange, new Date().toISOString()); // update time domain when new data available
      setTimeDomain({minX: newMinX, maxX: newMaxX});
      setGraphData(data);
    }
  }, [latestDynamicData, dynamicData]);

  useEffect(() => {
    setParam(`${id}?timeframe=${selectedDateRange}`);
    sessionStorage.setItem('sessionTimeFrame', selectedDateRange);
    onSelectedDateRangeChange(selectedDateRange);
  }, [selectedDateRange, onSelectedDateRangeChange]);

  // TimeFrame selector
  const handleChange = useCallback((event: SelectChangeEvent) => {
    const newTimeFrame = event?.target?.value as string;
    sessionStorage.setItem('sessionTimeFrame', newTimeFrame);
    setSelectedDateRange(newTimeFrame);
    onSelectedDateRangeChange(newTimeFrame);
  }, []);

  if (isLoadingDynamicData) {
    return (
      <div style={{width: '100%', height: '400px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
        <CircularProgress size={40} color="success" />
      </div>
    );
  }

  return (
    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
      <Box sx={{width: 300, marginTop: 5, display: 'flex', alignItems: 'center', paddingLeft: 2}}>
        <FormControl fullWidth>
          <StyledInputLabel id="demo-simple-select-label">Time frame</StyledInputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={selectedDateRange}
            label="Time frame"
            onChange={handleChange}>
            <MenuItem value={ENDPOINT_TIME_OPTIONS.HOUR}>1 Hour</MenuItem>
            <MenuItem value={ENDPOINT_TIME_OPTIONS.THREE_HOURS}>3 Hours</MenuItem>
            <MenuItem value={ENDPOINT_TIME_OPTIONS.SIX_HOURS}>6 Hours</MenuItem>
            <MenuItem value={ENDPOINT_TIME_OPTIONS.EIGHT_HOURS}>8 Hours</MenuItem>
            <MenuItem value={ENDPOINT_TIME_OPTIONS.TEN_HOURS}>10 Hours</MenuItem>
            <MenuItem value={ENDPOINT_TIME_OPTIONS.TWELVE_HOURS}>12 Hours</MenuItem>
            <MenuItem value={ENDPOINT_TIME_OPTIONS.SIXTEEN_HOURS}>16 Hours</MenuItem>
            <MenuItem value={ENDPOINT_TIME_OPTIONS.DAY}>24 Hours</MenuItem>
            <MenuItem value={ENDPOINT_TIME_OPTIONS.THREE_DAYS}>3 Days</MenuItem>
            <MenuItem value={ENDPOINT_TIME_OPTIONS.WEEK}>1 Week</MenuItem>
            <MenuItem value={ENDPOINT_TIME_OPTIONS.TWO_WEEKS}>2 Weeks</MenuItem>
            <MenuItem value={ENDPOINT_TIME_OPTIONS.MONTH}>1 Month</MenuItem>
            <MenuItem value={ENDPOINT_TIME_OPTIONS.ALL_TIME}>3 Months</MenuItem>
          </Select>
        </FormControl>
        <div style={{width: '2rem', marginLeft: 5}}>
          {isLoadingDynamicData && <CircularProgress size={20} sx={{marginLeft: 1}} color={'success'} />}
        </div>
      </Box>
      {graphData && (
        <div>
          <BloodGlucoseChart
            {...graphData}
            crosshairTime={crosshairTime}
            setCrosshairTime={setCrosshairTime}
            selectedDateRange={selectedDateRange}
            minX={timeDomain.minX}
            maxX={timeDomain.maxX}
          />
          <InsulinAndMealsChart
            {...graphData}
            crosshairTime={crosshairTime}
            setCrosshairTime={setCrosshairTime}
            basalProfiles={basalProfiles}
            selectedDateRange={selectedDateRange}
            minX={timeDomain.minX}
            maxX={timeDomain.maxX}
            TDI={TDI}
          />
          <BolusAndTreatmentChart
            {...graphData}
            crosshairTime={crosshairTime}
            setCrosshairTime={setCrosshairTime}
            selectedDateRange={selectedDateRange}
            minX={timeDomain.minX}
            maxX={timeDomain.maxX}
          />
        </div>
      )}
    </div>
  );
};
