import {useTheme, Typography} from '@mui/material';
import {CircularProgress, customToast} from '../../components';
import {EventsTableFilter} from '../../sections';
import {EventResponse, participantsService} from '../../api';
import {pollingInterval} from '../../constants';
import {StyledContainer} from './styles';
import {NoData} from '../../components/data-tables/utils';
import {useWebSocket} from '../../hooks/useWebSocket';
import {ToastContainer} from 'react-toastify';

// Component for loading screen
const LoadingScreen = () => (
  <div style={{width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
    <CircularProgress size={40} />
  </div>
);

// Component for error screen
const ErrorScreen = ({onRefetch}: {onRefetch: () => Promise<void>}) => (
  <div
    style={{
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    }}>
    <Typography variant="h6">Error</Typography>
    <button onClick={onRefetch}>Reload data</button>
  </div>
);

export const EventsPage = () => {
  const theme = useTheme();

  useWebSocket((event: EventResponse) => {
    if (event.priority === 3) customToast({event});
  });

  const {
    data: participants,
    isLoading: areParticipantsLoading,
    error: participantsError,
    refetch: refetchParticipants,
  } = participantsService.useGetParticipantsQuery(undefined, {pollingInterval});

  const onRefetch = async () => {
    await refetchParticipants();
  };

  // Loader state
  if (areParticipantsLoading) {
    return <LoadingScreen />;
  }

  // Error state
  if (participantsError) {
    return <ErrorScreen onRefetch={onRefetch} />;
  }

  // No participants data state
  if (!participants) {
    return <NoData />;
  }

  return (
    <StyledContainer>
      <ToastContainer limit={10} />
      <Typography variant="h2" color={theme.palette.basic[1000]}>
        Events
      </Typography>
      <EventsTableFilter participants={participants} />
    </StyledContainer>
  );
};
