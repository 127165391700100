import {useTheme, Typography} from '@mui/material';

import {CircularProgress, customToast} from '../../components';
import {ParticipantsTable} from '../../sections';
import {participantsService, clinicalTrialsService, measurementsService, EventResponse} from '../../api';
import {pollingInterval} from '../../constants';
import {StyledContainer} from './styles';
import {useWebSocket} from '../../hooks/useWebSocket';
import {ToastContainer} from 'react-toastify';

export const ParticipantsPage = () => {
  const theme = useTheme();

  const {
    data: participants,
    isLoading: areParticipantsLoading,
    error: participantsError,
    refetch: refetchParticipants,
  } = participantsService.useGetParticipantsQuery(undefined, {pollingInterval});

  const {
    data: clinicalTrials,
    isLoading: areClinicalTrialsLoading,
    error: clinicalTrialsError,
    refetch: refetchClinicalTrials,
  } = clinicalTrialsService.useGetClinicalTrialsQuery(undefined, {pollingInterval});

  // TODO: use useGetLatestCgmMeasurementsQuery instead of useGetLatestCgmActiveMeasurementsQuery
  const {
    data: latestCgmMeasurements,
    isLoading: areLatestCgmMeasurementsLoading,
    error: latestCgmMeasurementsError,
    refetch: refetchLatestCgmMeasurements,
  } = measurementsService.useGetLatestCgmMeasurementsQuery(undefined, {pollingInterval});

  const onRefetch = async () => {
    refetchParticipants();
    refetchClinicalTrials();
    refetchLatestCgmMeasurements();
  };

  useWebSocket((event: EventResponse) => {
    if (event.priority === 3) customToast({event});
  });

  // TODO: This is a temporary solution. We have to discuss and choose some solution
  if (areParticipantsLoading || areClinicalTrialsLoading || areLatestCgmMeasurementsLoading) {
    return (
      <div style={{width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
        <CircularProgress size={40} />
      </div>
    );
  }

  // TODO: This is a temporary solution. We have to discuss and choose some solution
  if (participantsError || clinicalTrialsError || latestCgmMeasurementsError) {
    return (
      <div
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        <h1>Error</h1>
        <button onClick={onRefetch}>Reload data</button>
      </div>
    );
  }

  if (!participants || !clinicalTrials || !latestCgmMeasurements) {
    throw new Error('participants, clinicalTrials or latestCgmMeasurements are undefined, unhandled state');
  }

  const hasParticipants = participants.length > 0;

  return (
    <StyledContainer>
      <ToastContainer limit={10} />
      <Typography variant="h2" color={theme.palette.basic[1000]}>
        Participants list - overview
      </Typography>
      {hasParticipants ? (
        <ParticipantsTable
          participants={participants}
          clinicalTrials={clinicalTrials}
          latestCgmMeasurements={latestCgmMeasurements}
        />
      ) : (
        /* TODO: This is a temporary solution. We have to discuss and choose some solution */
        <div>No Participants to display</div>
      )}
    </StyledContainer>
  );
};
