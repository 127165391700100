import {useTheme, Typography, Tooltip, Box} from '@mui/material';
import BatteryCharging90Icon from '@mui/icons-material/BatteryCharging90';
import {Icon, InsulinTrendIcon, ReservoirIcon} from '../../../components';
import {DeviceCardProps} from './types';
import {getIconColor, getMode} from '../../../../src/utils';

import {
  StyledContainer,
  StyledContent,
  StyledLastUpdate,
  StyledTimeValue,
  StyledValueContainer,
  StyledValue,
  StyledOperationMode,
  StyledIconButton,
} from './styles';

export const DeviceCard = ({
  cardHeading,
  showReservoirDetails,
  reservoirPercentage,
  showBatteryPercentage,
  batteryPercentage,
  lastUpdatedTimeTitle,
  lastUpdatedTime,
  showReadingValueAndUnit,
  showLastHoursInsulin,
  showLoopMode,
  readingValue,
  secondReadingValue,
  readingUnit,
  readingTrend,
  readingMode,
  isInferred,
  isTimeAgo,
  isDeviceCard,
  tooltipText,
  showLastCarboMeal,
  showInsulinTime,
}: DeviceCardProps): JSX.Element => {
  const theme = useTheme();
  const iconColor = showReadingValueAndUnit && readingValue ? getIconColor(isTimeAgo, theme) : theme.palette.basic[600];

  return (
    <StyledContainer sx={{height: '100%', maxWidth: '500px'}}>
      <StyledValueContainer sx={{width: '100%', justifyContent: 'space-between', alignItems: 'flex-start'}}>
        <StyledLastUpdate>
          <StyledOperationMode sx={{alignItems: 'flex-start', flexWrap: 'nowrap', flexDirection: 'row'}}>
            <Typography variant="body1" sx={{fontWeight: '600', color: `${theme.palette.basic[900]}`}}>
              {cardHeading}
            </Typography>
            <Tooltip title={tooltipText}>
              <StyledIconButton style={{marginTop: '1px'}}>
                <Icon icon="info" size={/*IsMobile() ? 12 :*/ 16} color={theme.palette.basic[500]} />
              </StyledIconButton>
            </Tooltip>
          </StyledOperationMode>
          {showLastCarboMeal && (
            <StyledTimeValue>
              <Typography variant="bodyBig" color={theme.palette.basic[900]}>
                {secondReadingValue ? readingMode : '--'}
              </Typography>
            </StyledTimeValue>
          )}
        </StyledLastUpdate>
        {isDeviceCard && (
          <Box sx={{display: 'flex', flexDirection: 'column', ml: '46px', alignItems: 'center', flexWrap: 'wrap'}}>
            {showReservoirDetails && (
              <StyledOperationMode>
                <Box sx={{width: '20px', height: '20px'}}>
                  <ReservoirIcon />
                </Box>
                <Typography
                  sx={{
                    fontSize: '12px',
                    color: '#535B62',
                    mt: '3.5px',
                    mr: '8px',
                    ml: '2px',
                    alignItems: 'flex-start',
                  }}>
                  {reservoirPercentage ? (reservoirPercentage ?? NaN) * 100 : '--'}% Insulin reservoir level
                </Typography>
              </StyledOperationMode>
            )}
            {showBatteryPercentage && (
              <StyledOperationMode>
                <BatteryCharging90Icon sx={{transform: 'rotate(-90deg)'}} />
                <Typography
                  sx={{
                    fontSize: '12px',
                    color: '#535B62',
                    mt: '3.5px',
                    mr: '8px',
                    ml: '2px',
                    alignItems: 'flex-start',
                  }}>
                  {batteryPercentage ? (batteryPercentage ?? NaN) * 100 : '--'}% Insulin pump battery
                </Typography>
              </StyledOperationMode>
            )}
            {showLoopMode && (
              <StyledOperationMode>
                <Typography
                  sx={{
                    fontSize: '1.2rem',
                    fontWeight: '700',
                    color: !isTimeAgo ? getMode(readingMode).color : `${theme.palette.basic[900]}`,
                    height: '1.8rem',
                    marginRight: '0.5rem',
                    textAlign: 'right',
                  }}>
                  {!readingMode || isTimeAgo ? '--' : getMode(readingMode).name}
                </Typography>
              </StyledOperationMode>
            )}
          </Box>
        )}
        {showLastCarboMeal && (
          <StyledValueContainer sx={{flexDirection: 'column', justifyContent: 'space-between', alignItems: 'flex-end'}}>
            <StyledValue style={{marginTop: '20px', marginRight: '1rem'}}>
              <Typography
                sx={{
                  fontSize: '1.4rem',
                  fontWeight: '700',
                  color: `${theme.palette.basic[900]}`,
                  height: '1.8rem',
                }}>
                {secondReadingValue ? secondReadingValue : '--'}
              </Typography>
              <Typography sx={{fontSize: '13px', fontWeight: '600', color: '#535B62'}}>g</Typography>
            </StyledValue>
          </StyledValueContainer>
        )}
      </StyledValueContainer>
      <StyledContent sx={{height: '100%'}}>
        <Box sx={{height: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
          <StyledLastUpdate sx={{marginTop: 'auto'}}>
            <Typography
              variant="body1"
              fontWeight={showLastCarboMeal ? '600' : '400'}
              color={showLastCarboMeal ? theme.palette.primaryCustom[900] : theme.palette.customColors?.lightGrey}
              marginRight={showLastHoursInsulin ? '1.5rem' : '0'}>
              {lastUpdatedTimeTitle}
            </Typography>
            {!showLastHoursInsulin && (
              <StyledTimeValue>
                {!showLastCarboMeal && (
                  <Tooltip
                    title={
                      <Typography sx={{fontSize: '11px'}}>
                        Green: last update less than 6 min ago
                        <br />
                        Red: last update more than 6 min ago
                      </Typography>
                    }
                    arrow>
                    <StyledIconButton>
                      <Icon icon="check-rounded" size={20} color={iconColor} />
                    </StyledIconButton>
                  </Tooltip>
                )}
                <Typography variant="bodyBig" color={theme.palette.basic[900]}>
                  {readingValue ? lastUpdatedTime : '--'}
                </Typography>
              </StyledTimeValue>
            )}
            {showLastHoursInsulin && (
              <StyledTimeValue>
                <Typography fontWeight="700" variant="bodyBig" color={'red'}>
                  {showInsulinTime ? 'Infused in the last ' + lastUpdatedTime + ' minutes!' : ''}
                </Typography>
              </StyledTimeValue>
            )}
          </StyledLastUpdate>
          {showReadingValueAndUnit && (
            <StyledValueContainer>
              <StyledValue>
                <Typography
                  sx={{
                    fontSize: '50px',
                    fontWeight: '700',
                    color: `${theme.palette.basic[900]}`,
                    WebkitTextStroke: isInferred && !isTimeAgo && readingValue ? '1.5px' : 'none',
                    WebkitTextFillColor:
                      isInferred && !isTimeAgo && readingValue ? 'transparent' : `${theme.palette.basic[900]}`,
                    height: '60px',
                  }}>
                  {readingValue && !isTimeAgo ? readingValue : '--'}
                </Typography>
                <Typography sx={{fontSize: '13px', fontWeight: '600', color: '#535B62'}}>{readingUnit}</Typography>
                {(!isTimeAgo && readingTrend) || readingTrend == 0 ? (
                  <InsulinTrendIcon trend={readingTrend} size={18} />
                ) : (
                  '--'
                )}
              </StyledValue>
            </StyledValueContainer>
          )}
          {showLastHoursInsulin && (
            <StyledValueContainer>
              <StyledValue style={{marginTop: '20px', marginRight: '0.5rem'}}>
                <Typography
                  sx={{fontSize: '1.4rem', fontWeight: '700', color: `${theme.palette.basic[900]}`, height: '1.8rem'}}>
                  {readingValue && batteryPercentage ? readingValue?.toFixed(2) : '--'}
                </Typography>
                <Typography sx={{fontSize: '13px', fontWeight: '600', color: '#535B62'}}>U</Typography>
              </StyledValue>
            </StyledValueContainer>
          )}
          {showLastCarboMeal && (
            <StyledValueContainer>
              <StyledValue style={{marginTop: '20px', marginRight: '1rem'}}>
                <Typography
                  sx={{fontSize: '1.4rem', fontWeight: '700', color: `${theme.palette.basic[900]}`, height: '1.8rem'}}>
                  {readingValue ? readingValue?.toFixed(2) : '--'}
                </Typography>
                <Typography sx={{fontSize: '13px', fontWeight: '600', color: '#535B62'}}>U</Typography>
              </StyledValue>
            </StyledValueContainer>
          )}
        </Box>
      </StyledContent>
    </StyledContainer>
  );
};
