import {wssUrl} from '../../constants';
import type {EventResponse} from '../../api/service-types';

class WSService {
  private static instance: WSService;
  private ws: WebSocket | null;
  private listeners: ((nextEvent: EventResponse) => void)[];
  private activeComponents = 0;

  constructor() {
    this.ws = null;
    this.listeners = [];
  }

  public static getInstance(): WSService {
    if (!WSService.instance) {
      WSService.instance = new WSService();
    }
    return WSService.instance;
  }

  connect() {
    if (this.ws) {
      if (this.ws.readyState === WebSocket.CONNECTING || this.ws.readyState === WebSocket.OPEN) {
        console.warn('WebSocket is already connected.');
        return;
      }
    }

    this.ws = new WebSocket(`${wssUrl}/event-notifications/ws`);

    this.ws.addEventListener('open', () => {
      console.log('✅ WebSocket opened!');
    });

    this.ws.addEventListener('close', () => {
      console.log('❌ WebSocket closed!');
      this.ws = null;
    });

    this.ws.addEventListener('error', event => {
      console.error('⚠️ WebSocket erro:', event);
    });

    this.ws.addEventListener('message', event => {
      try {
        const nextEvent: EventResponse = JSON.parse(event.data);
        console.log('📩 New event received:', nextEvent);

        this.listeners.forEach(callback => callback(nextEvent));
      } catch (error) {
        console.error('❌ Error:', error);
      }
    });
  }

  cleanup() {
    if (this.ws) {
      this.ws.onclose = null; // Remove handlers to prevent memory leaks
      this.ws.onerror = null;
      this.ws.close(); // Graceful close
      this.ws = null;
    }
    this.listeners = [];
  }

  disconnect() {
    this.cleanup(); // Use the safer cleanup method
  }

  listener(callback: (nextEvent: EventResponse) => void) {
    if (!this.ws) {
      console.warn('⚠️ WebSocket not connected!');
      this.connect();
    }

    this.listeners.push(callback);
  }

  isConnected(): boolean {
    return this.ws !== null && this.ws.readyState === WebSocket.OPEN;
  }

  removeListener(callback: (event: EventResponse) => void): void {
    this.listeners = this.listeners.filter(listener => listener !== callback);
  }

  componentConnect() {
    this.activeComponents++;
    if (!this.isConnected()) {
      this.connect();
    }
  }

  componentDisconnect() {
    this.activeComponents--;
    if (this.activeComponents <= 0) {
      this.disconnect();
    }
  }
}
export const wsService = WSService.getInstance();
