import {ParticipantDateRangeOptions} from '../api';

export const DATE_TIME = {
  FULL_DATE_TIME_AM: 'DD/MM/YY, HH:mm',
  YYYY_MM_DD_T_HH_mm: 'YYYY-MM-DDTHH:mm',
};

export const DATE_RANGE_OPTIONS = {
  ALL_TIME: 'All time',
  LAST_24_HRS: 'Last 24 hours',
  LAST_3_DAYS: 'Last 3 days',
  LAST_7_DAYS: 'Last 7 days',
  LAST_14_DAYS: 'Last 14 days',
  LAST_MONTH: 'Last month',
};

export const PARTICIPANT_DATE_RANGE_OPTIONS: ParticipantDateRangeOptions[] = [
  {id: 0, label: 'LAST 1 HOUR', value: 'Last 1 hour'},
  {id: 1, label: 'LAST 6 HOURS', value: 'Last 6 hours'},
  {id: 2, label: 'LAST 12 HOURS', value: 'Last 12 hours'},
  {id: 3, label: 'LAST 48 HOURS', value: 'Last 48 hours'},
  {id: 4, label: 'LAST 7 DAYS', value: 'Last 7 days'},
  {id: 5, label: 'LAST 14 DAYS', value: 'Last 14 days'},
  {id: 6, label: 'ALL TIME', value: 'All time'},
];

export const LAST_1_HR_TIMELINE_OPTION: string[] = ['12:00 am', '12:20 am', '12:40 am', '01:00 am'];
export const LAST_6_HRS_TIMELINE_OPTION: string[] = [
  '12:00 am',
  '01:00 am',
  '02:00 am',
  '03:00 am',
  '04:00 am',
  '05:00 am',
  '06:00 am',
];
export const LAST_12_HRS_TIMELINE_OPTION: string[] = [
  '12:00 am',
  '02:00 am',
  '04:00 am',
  '06:00 am',
  '08:00 am',
  '10:00 am',
  '12:00 pm',
];
export const LAST_48_HRS_TIMELINE_OPTION: string[] = [
  '12:00 am',
  '08:00 am',
  '04:00 pm',
  '12:00 am',
  '08:00 pm',
  '04:00 pm',
  '12:00 am',
];
export const LAST_7_DAYS_TIMELINE_OPTION: string[] = ['Mon', 'Tue', 'Wed', 'Thurs', 'Fri', 'Sat', 'Sun'];
export const LAST_14_DAYS_TIMELINE_OPTION: string[] = [
  'Mon',
  'Tue',
  'Wed',
  'Thurs',
  'Fri',
  'Sat',
  'Sun',
  'Mon',
  'Tue',
  'Wed',
  'Thurs',
  'Fri',
  'Sat',
  'Sun',
];

export const ENDPOINT_TIME_OPTIONS = {
  HOUR: '1h',
  THREE_HOURS: '3h',
  SIX_HOURS: '6h',
  EIGHT_HOURS: '8h',
  TEN_HOURS: '10h',
  TWELVE_HOURS: '12h',
  SIXTEEN_HOURS: '16h',
  DAY: 'day',
  THREE_DAYS: 'three-days',
  WEEK: 'week',
  TWO_WEEKS: 'two-weeks',
  MONTH: 'month',
  ALL_TIME: 'all',
};
