import {TableHeadCell} from '../../../components';
import {EventStatus, Priority} from './types';

interface Event {
  type: string;
  id: number;
  participantId: number;
  commentThreadId: null | number;
  assignedUserId: null | number;
  priority: number;
  text: string;
  status: null | string;
  eventDateTime: string;
}

interface EventObject {
  event: Event;
  participantName: string;
  assignedUserName: null | string;
  commentCount: number;
  // ... other properties
}

export interface FormattedEventType {
  id: number;
  type: string;
}

export interface FormattedStatus {
  id: number;
  status: string;
}

export interface OriginalStatusFiltersObj {
  [key: string]: string;
}

interface TransformedStatusFiltersArr {
  id: number;
  status: string;
}

export const formatEventType = (type: string, id: number): FormattedEventType => {
  const parts = type
    .split('.')
    .pop()
    ?.match(/[A-Z][a-z]*/g);
  if (!parts) {
    return {id, type}; // fallback to the original type if the format is unexpected
  }
  const formattedType = parts.map(part => part.charAt(0).toUpperCase() + part.slice(1)).join(' ');
  return {id, type: formattedType};
};

export const getUniqueFormattedEventTypes = (events: EventObject[]): FormattedEventType[] => {
  const uniqueTypesMap = new Map<string, number>();
  const result: FormattedEventType[] = [];

  events?.forEach(eventObject => {
    const {type} = eventObject.event;
    if (!uniqueTypesMap.has(type)) {
      uniqueTypesMap.set(type, result.length);
      result.push(formatEventType(type, result.length));
    }
  });

  return result;
};

export const formatStatus = (status: null | string, id: number): FormattedStatus => {
  return {id, status: status === null ? 'null' : status};
};

export const capitalizeFirstLetter = (str: string): string => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const convertStatusFiltersObjToArr = (
  originalObject: OriginalStatusFiltersObj,
): TransformedStatusFiltersArr[] => {
  return Object.keys(originalObject).map(key => ({
    id: parseInt(key),
    status: capitalizeFirstLetter(originalObject[key]),
  }));
};

export const formatEventTypeResponse = (type: string): string => {
  // Extract the last part after the last dot
  const lastPart = type?.split('.').pop() || '';
  // Split the last part by uppercase letters and join with space
  const formattedPart = lastPart?.split(/(?=[A-Z])/).join(' ');

  return formattedPart;
};

export const EventTypes = [
  'exercise_event',
  'meal_event',
  'note_event',
  'basal_profile_change_event',
  'temporal_basal_change_event',
  'insulin_pump_event',
  'cgm_event',
  'phone_event',
  'app_event',
  'controller_event',
  'application_control_mode_change_event',
  'insulin_computed_event',
  'cgm_measurement',
  'ketones_measurement',
  'glucometer_measurement',
];

export const eventTypesMap: Record<string, string> = {
  AUTO_SWITCH_TO_OPEN_LOOP: 'Auto switch to OL',
  AUTO_SWITCH_TO_CLOSED_LOOP: 'Auto switch to CL',
  INSULIN_NOT_INJECTED: 'Insulin NOT injected',
  INSULIN_PARTIALLY_INJECTED: 'Insulin partially injected',
  INSULIN_BOLUS_TOO_BIG: 'Large insulin bolus',
  INSULIN_PUMP_BATTERY_LOW: 'Low pump battery',
  INSULIN_PUMP_BATTERY_CRITICALLY_LOW: 'Critically low pump battery',
  INSULIN_PUMP_RESERVOIR_LOW: 'Low pump reservoir',
  INSULIN_PUMP_RESERVOIR_CRITICALLY_LOW: 'Critically low pump reservoir',
  PHONE_BATTERY_LOW: 'Low phone battery',
  PHONE_BATTERY_CRITICALLY_LOW: 'Critically low phone battery',
  HYPOGLYCEMIA: 'Hypoglycemia',
  HYPOGLYCEMIA_VERY_LOW: 'Very low glucose level',
  HYPERGLYCEMIA_SUSTAINED: 'Sustained hyperglycemia',
  HYPERGLYCEMIA_HIGH: 'High glucose level',
  HYPERGLYCEMIA_VERY_HIGH: 'Very high glucose level',
  GLUCOSE_INCREASING_FAST: 'Glucose increasing fast',
  GLUCOSE_INCREASING_FASTER: 'Glucose increasing very fast',
  GLUCOSE_DECREASING_FAST: 'Glucose decreasing fast',
  GLUCOSE_DECREASING_FASTER: 'Glucose decreasing very fast',
  INSULIN_PUMP_DISCONNECT: 'Insulin pump disconnected',
  CGM_UNEXPECTED_DISCONNECT: 'CGM disconnected',
  INSULIN_SAFETY_NET_VIOLATION: 'Safety net violation',
  INSULIN_PUMP_REMAINDER_HIGH: 'Insulin remainder high',
  INSULIN_PUMP_REMAINDER_VERY_HIGH: 'Insulin remainder very high',
  BIG_MEAL_INSERTED: 'Large meal',
  SMBG_VALUE_ADDED: 'New SMBG',
  NEW_NOTE_ADDED: 'New note',
  MANUAL_IOB_ADJUSTMENT_ADDED: 'Manual IOB',
  CGM_GENERIC_ERROR: 'CGM error',
  CGM_NEEDS_REPLACEMENT: 'Replace CGM',
};

export const EventTypesFormatString = (str: string) => {
  if (!str) return '';
  const formattedStr = str.replace(/_/g, ' '); // Remove underlines
  return formattedStr.charAt(0).toUpperCase() + formattedStr.slice(1); // Capitaliza a primeira letra
};

export const eventStatus: EventStatus[] = [
  {id: 1, status: 'New'},
  {id: 2, status: 'In progress'},
  {id: 3, status: 'Completed'},
];

export const priorityMap: Record<number, Priority> = {
  1: {label: 'Low', color: '#999999'},
  2: {label: 'Medium', color: '#cccc00'},
  3: {label: 'High', color: '#cc0000'},
};

export const cells: TableHeadCell[] = [
  {label: 'Event ID', align: 'center'},
  {label: 'Event type'},
  {label: 'Participant ID', align: 'center'},
  {label: 'Priority', align: 'center'},
  {label: 'Status', align: 'center'},
  {label: 'Assignee', align: 'center'},
  {label: 'No. of comments', align: 'center'},
  {label: 'Website user time'},
  {label: 'Participant time'},
];
